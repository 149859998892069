import React, { useEffect, useRef } from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components

const LazyMailComp = React.lazy(() => import("./LazyMailComp"));
const LazyTelComp = React.lazy(() => import("./LazyTelComp"));
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
// import Button from "@material-ui/core/Button";
import PermPhoneMsgIcon from "@material-ui/icons/PermPhoneMsg";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import LocationOnIcon from "@material-ui/icons/LocationOn";

import styles from "./contattiStyle";
const useStyles = makeStyles(styles);

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

export default function ContattiBox(props) {
  const [riferimentoTel, setRiferimentoTel] = React.useState(false);
  const [riferimentoMail, setRiferimentoMail] = React.useState(false);
  const caricaMail = (event) => {
    event.preventDefault();
    setRiferimentoMail(<LazyMailComp />);
  };

  const caricaTel = (event) => {
    event.preventDefault();
    setRiferimentoTel(<LazyTelComp />);
  };

  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div className={classes.description}>
      <List>
        <ListItem alignItems="flex-start" className={classes.noPaddingLeft}>
          <ListItemAvatar>
            <PermPhoneMsgIcon className={classes.iconLink} fontSize="large" />
          </ListItemAvatar>
          <ListItemText>
            Basta una telefonata: <br />
            {riferimentoTel ? (
              <React.Suspense fallback={<div>loading...</div>}>
                <div className={classes.iconLink}>{riferimentoTel}</div>
              </React.Suspense>
            ) : (
              <Button
                color="primary"
                onClick={caricaTel}
                size="sm"
                variant="outlined"
              >
                visualizza
              </Button>
            )}
          </ListItemText>
        </ListItem>
        <ListItem alignItems="flex-start" className={classes.noPaddingLeft}>
          <ListItemAvatar>
            <ContactMailIcon className={classes.iconLink} fontSize="large" />
          </ListItemAvatar>

          <ListItemText>
            Oppure una e-mail:
            <br />
            {riferimentoMail ? (
              <React.Suspense fallback={<div>loading...</div>}>
                <div className={classes.iconLink}>{riferimentoMail}</div>
              </React.Suspense>
            ) : (
              <Button
                color="primary"
                onClick={caricaMail}
                size="sm"
                variant="outlined"
              >
                visualizza
              </Button>
            )}
          </ListItemText>
        </ListItem>
        <ListItem alignItems="flex-start" className={classes.noPaddingLeft}>
          <ListItemAvatar>
            <LocationOnIcon className={classes.iconLink} fontSize="large" />
          </ListItemAvatar>

          <ListItemText>
            Ci trovi a:
            <br />
            <a>Venezia, Conegliano, Auronzo</a>
          </ListItemText>
        </ListItem>
      </List>
    </div>
  );
}
