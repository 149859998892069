import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import ContattiShortComp from "../../Contatti/ContattiShortComp";

// @material-ui/icons
import AvTimer from "@material-ui/icons/AvTimer";
import EuroSymbol from "@material-ui/icons/EuroSymbol";
import EmojiEvents from "@material-ui/icons/EmojiEvents";
import People from "@material-ui/icons/People";
import ListAlt from "@material-ui/icons/ListAlt";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Link from "@material-ui/core/Link";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";

import styles from "../pacchettiStyle.js";
import KaizenOfficeImage from "../../../assets/img/AgileKanban.jpg";

const useStyles = makeStyles(styles);

export default function KaizenOfficeSection() {
	const [expanded, setExpanded] = React.useState(false);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	const classes = useStyles();
	const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
	return (
		<div className={classes.sectionAlternate}>
			<GridContainer justify="center">
				<GridItem xs={12} sm={12} md={10}>
					<h2 className={classes.title + " " + classes.textColorAlternate}>
						Kaizen Office
					</h2>
					<GridContainer justify="center">
						<GridItem xs={12} sm={12} md={4}>
							<List>
								<ListItem alignItems="flex-start">
									<ListItemAvatar>
										<AvTimer color="error" fontSize="large" />
									</ListItemAvatar>
									<ListItemText
										disableTypography
										secondary={
											<React.Fragment>
												<Typography
													component="div"
													variant="h6"
													className={classes.textColorAlternate}
												>
													{"Durata: "}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={
														classes.descrizioneVoce +
														" " +
														classes.textColorAlternate
													}
												>
													{
														"Un incontro di formazione e pianificazione (4 ore) + 4 attività di Kaizen/Retrospective (2 ore ciascuna)."
													}
												</Typography>
											</React.Fragment>
										}
									/>
								</ListItem>
								<hr className={classes.hrMargin} />
								{/* <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <EuroSymbol
                      className={classes.successColor}
                      fontSize="large"
                    />
                  </ListItemAvatar>
                  <ListItemText
                    disableTypography
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="div"
                          variant="h6"
                          className={classes.textColorAlternate}
                        >
                          {"Costo: "}
                        </Typography>
                        <Typography
                          component="div"
                          variant="body1"
                          className={
                            classes.descrizioneVoce +
                            " " +
                            classes.textColorAlternate
                          }
                        >
                          {"1.600 + IVA per gruppo"}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <hr className={classes.hrMargin} /> */}
								<ListItem alignItems="flex-start">
									<ListItemAvatar>
										<EmojiEvents
											className={classes.warningColor}
											fontSize="large"
										/>
									</ListItemAvatar>
									<ListItemText
										disableTypography
										secondary={
											<React.Fragment>
												<Typography
													component="div"
													variant="h6"
													className={classes.textColorAlternate}
												>
													{"Obiettivi: "}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={
														classes.descrizioneVoceLista +
														" " +
														classes.textColorAlternate
													}
												>
													{
														"Disegnare ed avviare un processo per l’erogazione di servizi con metodologia Agile Kanban."
													}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={
														classes.descrizioneVoceLista +
														" " +
														classes.textColorAlternate
													}
												>
													{
														"Attivare un ciclo di miglioramento continuo in stile Kaizen/Retrospective."
													}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={
														classes.descrizioneVoceLista +
														" " +
														classes.textColorAlternate
													}
												>
													{"Migliorare la soddisfazione del cliente."}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={
														classes.descrizioneVoceLista +
														" " +
														classes.textColorAlternate
													}
												>
													{"Migliorare la capacità di collaborazione del team."}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={
														classes.descrizioneVoceLista +
														" " +
														classes.textColorAlternate
													}
												>
													{
														"Favorire un ambiente di lavoro in cui operare con serenità e soddisfazione."
													}
												</Typography>
											</React.Fragment>
										}
									/>
								</ListItem>
								<hr className={classes.hrMargin} />
							</List>
						</GridItem>
						<GridItem xs={12} sm={12} md={4}>
							<List>
								<ListItem alignItems="flex-start">
									<ListItemAvatar>
										<People style={{ color: "#ddd" }} fontSize="large" />
									</ListItemAvatar>
									<ListItemText
										disableTypography
										secondary={
											<React.Fragment>
												<Typography
													component="div"
													variant="h6"
													className={classes.textColorAlternate}
												>
													{"Destinatari: "}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={
														classes.descrizioneVoceLista +
														" " +
														classes.textColorAlternate
													}
												>
													{
														"Persone o gruppi di persone che si occupano di attività di servizio sia verso utenti finali che verso utenti interni."
													}
												</Typography>
											</React.Fragment>
										}
									/>
								</ListItem>
								<hr className={classes.hrMargin} />
								<ListItem alignItems="flex-start">
									<ListItemAvatar>
										<ListAlt className={classes.infoColor} fontSize="large" />
									</ListItemAvatar>
									<ListItemText
										disableTypography
										secondary={
											<React.Fragment>
												<Typography
													component="div"
													variant="h6"
													className={classes.textColorAlternate}
												>
													{"Programma: "}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={
														classes.descrizioneVoceLista +
														" " +
														classes.textColorAlternate
													}
												>
													{"Introduzione alla Lean Office, ai "}
													<Link href="/metodi/agile" color="error">
														principi Agile
													</Link>
													{" ed alla metodologia Agile Kanban."}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={
														classes.descrizioneVoceLista +
														" " +
														classes.textColorAlternate
													}
												>
													{
														"Disegno e pianificazione del processo di erogazione servizi."
													}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={
														classes.descrizioneVoceLista +
														" " +
														classes.textColorAlternate
													}
												>
													{
														"1 Meeting di  Kaizen/Retrospective ogni 2 settimane per 4 volte."
													}
												</Typography>
											</React.Fragment>
										}
									/>
								</ListItem>
								<hr className={classes.hrMargin} />
								<ContattiShortComp textColor="#fff" align="right" alternate />
							</List>
						</GridItem>
						<GridItem xs={12} sm={12} md={4}>
							<img
								alt="Kaizen Office Kanban"
								title="Kaizen Office Kanban"
								src={KaizenOfficeImage}
								className={navImageClasses}
							/>
						</GridItem>
					</GridContainer>
				</GridItem>
			</GridContainer>
		</div>
	);
}
