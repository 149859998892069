import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import Header from "../../../components/Header/Header";
import HeaderLinks from "../../../components/Header/HeaderLinks";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components

import MetodiBreadcrumbs from "../MetodiBreadcrumbs";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import Grid from "@material-ui/core/Grid";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import FocalizzazioneCard from "./FocalizzazioneCard";
import CollaborazioneCard from "./CollaborazioneCard";
import CiclicitàCard from "./CiclicitàCard";
import ScomposizioneCard from "./ScomposizioneCard";
import NoccioloCard from "./NoccioloCard";
import Button from "components/CustomButtons/Button.js";

import styles from "../metodiStyle.js";
import MetodiComuneImage from "../../../assets/img/hero-metodiInsiemeImageMedium.jpg";

const useStyles = makeStyles(styles);

export default function MetodiComunePage(props) {
  const topRef = useRef(null);
  const executeScrollToTop = () => {
    topRef.current.scrollIntoView();
  };
  useEffect(executeScrollToTop);

  const classes = useStyles();

  return (
    <div ref={topRef}>
      <Header
        color="transparent"
        brand="Webylia"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "white",
        }}
      />
      <Parallax medium filter image={MetodiComuneImage}>
        <div className={classes.parallaxContainer}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <h1 className={classes.parallaxTitle}>
                Analogie tra Design Thinking, Agile e Lean
              </h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div
        className={classNames(
          classes.main,
          classes.mainRaised,
          classes.stratiSection
        )}
      >
        <MetodiBreadcrumbs dark={true} />
        <GridContainer
          justify="center"
          spacing={8}
          alignItems="center"
          alignContent="center"
          style={{ textAlign: "center" }}
        >
          <GridItem xs={12} sm={12} lg={12}>
            <h2 className={classes.textColorAlternate}>
              <Box fontWeight="fontWeightMedium">COSA HANNO IN COMUNE</Box>
            </h2>
          </GridItem>
          <GridItem xs={12} sm={6} lg={3}>
            <FocalizzazioneCard />
          </GridItem>
          <GridItem xs={12} sm={6} lg={3}>
            <CollaborazioneCard />
          </GridItem>
          <GridItem xs={12} sm={6} lg={3}>
            <CiclicitàCard />
          </GridItem>
        </GridContainer>

        <GridContainer
          justify="center"
          spacing={8}
          alignItems="center"
          alignContent="center"
          style={{ textAlign: "center" }}
        >
          <GridItem xs={12} sm={6} lg={3}>
            <ScomposizioneCard />
          </GridItem>
          <GridItem xs={12} sm={6} lg={3}>
            <NoccioloCard />
          </GridItem>
        </GridContainer>

        <Grid
          spacing={1}
          container
          justify="center"
          alignContent="center"
          alignItems="center"
          style={{ padding: "0rem 1rem 3rem 1rem " }}
        >
          <Grid item>
            <Button
              size="lg"
              fullWidth
              variant="text"
              component={Link}
              to="/metodi/insieme"
              style={{
                backgroundColor: "#f8d300",
                color: "#222222",
                fontSize: "1rem",
                marginTop: "2rem",
                minHeight: "4rem",
                minWidth: "24vw",
              }}
            >
              <Box component="div" whiteSpace="normal">
                Design Thinking, Agile e Lean: come funzionano insieme?
              </Box>
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
