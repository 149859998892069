import React from "react";
import { Link } from "react-router-dom";

import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import { makeStyles } from "@material-ui/core/styles";

import Button from "../components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
import styles from "./LandingPage/landingPageStyle.js";
import inCostruzioneImages from "../assets/img/under_construction.jpeg";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

function InCostruzione(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="Webylia"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Parallax filter image={inCostruzioneImages}>
        <div className={classes.parallaxContainer}>
          <h1>Pagina in Costruzione</h1>

          <Button
            style={{
              backgroundColor: "#f8d300",
              color: "#222222",
            }}
            component={Link}
            to="/"
          >
            Back to Homepage
          </Button>
        </div>
      </Parallax>
    </>
  );
}

export default InCostruzione;
