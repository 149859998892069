import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Header from "./components/Header/Header.js";
import HeaderLinks from "./components/Header/HeaderLinks.js";

import { makeStyles } from "@material-ui/core/styles";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";

import styles from "./views/LandingPage/landingPageStyle.js";

import "./assets/scss/material-kit-react.scss?v=1.9.0";

// pages for this product
import LandingPage from "./views/LandingPage/LandingPage";
import Pacchetti from "./views/Pacchetti/PacchettiPage";
import Servizi from "./views/Servizi/ServiziPage";
import Metodi from "./views/Metodi/MetodiPage";
import MetodiInsieme from "./views/Metodi/MetodiInsieme/MetodiInsiemePage";
import MetodiComune from "./views/Metodi/MetodiInsieme/MetodiComunePage";
import Agile from "./views/Metodi/AgilePage";
import DesignThink from "./views/Metodi/DesignThinkPage";
import Lean from "./views/Metodi/LeanPage";
import ChisiamoPage from "./views/ChiSiamoPage/ChisiamoPage";
import Contatti from "./views/Contatti/ContattiPage";
import CookiePolicy from "./views/CookiePolicy/CookiePolicy";
import InCostruzione from "./views/InCostruzione";
import NotFoundPage from "./views/NotFoundPage";
import Footer from "./components/Footer/Footer.js";
var hist = createBrowserHistory();

const useStyles = makeStyles(styles);

function App() {
	const classes = useStyles();

	return (
		<React.Fragment>
			{/* <Header
        color="transparent"
        // routes={dashboardRoutes}
        brand="Webylia"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "white",
        }}
        // {...rest}
      /> */}
			{/* <Router history={hist}> */}

			<Switch>
				<Redirect from="/home_sw" to="/?utm_source=sn&utm_medium=whatsapp" />
				<Redirect from="/home_sl" to="/?utm_source=sn&utm_medium=linkedin" />
				<Redirect from="/home_sm" to="/?utm_source=sn&utm_medium=mail" />
				<Redirect from="/home_rw" to="/?utm_source=rj&utm_medium=whatsapp" />
				<Redirect from="/home_rl" to="/?utm_source=rj&utm_medium=linkedin" />
				<Redirect from="/home_rm" to="/?utm_source=rj&utm_medium=mail" />
				<Redirect from="/home_fw" to="/?utm_source=ff&utm_medium=whatsapp" />
				<Redirect from="/home_fl" to="/?utm_source=ff&utm_medium=linkedin" />
				<Redirect from="/home_fm" to="/?utm_source=ff&utm_medium=mail" />
				<Route exact path={"/"} component={LandingPage} />
				<Route exact path={"/home"} component={LandingPage} />
				<Route exact path={"/landing-page"} component={LandingPage} />
				<Route exact path={"/pacchetti"} component={Pacchetti} />
				<Route exact path={"/servizi"} component={Servizi} />
				<Route exact path={"/metodi"} component={Metodi} />
				<Route exact path={"/metodi/in_comune"} component={MetodiComune} />
				<Route exact path={"/metodi/insieme"} component={MetodiInsieme} />
				<Route exact path={"/metodi/agile"} component={Agile} />
				<Route exact path={"/metodi/designthinking"} component={DesignThink} />
				<Route exact path={"/metodi/lean"} component={Lean} />
				<Route exact path={"/chisiamo"} component={ChisiamoPage} />
				<Route exact path={"/contatti"} component={Contatti} />
				<Route exact path={"/cookie-policy"} component={CookiePolicy} />
				{/* <Route exact path={"/in-costruzione"} component={InCostruzione} /> */}
				<Route component={NotFoundPage} />
			</Switch>

			<CookieConsent
				location="bottom"
				cookieName="technicalCookie"
				expires={30} //Number of days before the cookie expires.
				// overlay
				// debug={true}
				buttonStyle={{ background: "#ffd42d" }}
				buttonText="Ok, ho capito."
			>
				Il sito di Webylia NON utilizza alcun cookie di profilazione, ma si
				avvale di cookie di tipo tecnico e di tipo analitico per informazioni
				aggregate ed anonime con finalità statistiche. Se vuoi saperne di più
				leggi&nbsp;
				<Link to="/cookie-policy">
					<span style={{ color: "#ffd42d" }}>
						la nostra informativa sull’uso dei cookie
					</span>
				</Link>
			</CookieConsent>
			<Footer />
			{/* </Router> */}
		</React.Fragment>
	);
}

export default App;
