import React, { useRef } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import ContattiShortComp from "../../Contatti/ContattiShortComp";

// @material-ui/icons
import AvTimer from "@material-ui/icons/AvTimer";
import EuroSymbol from "@material-ui/icons/EuroSymbol";
import EmojiEvents from "@material-ui/icons/EmojiEvents";
import People from "@material-ui/icons/People";
import ListAlt from "@material-ui/icons/ListAlt";

import Link from "@material-ui/core/Link";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";

import DesignThinkingImage from "../../../assets/img/DTSpike.png";
import styles from "../pacchettiStyle.js";

const useStyles = makeStyles(styles);

export default function DesignThinkingSection() {
	const classes = useStyles();
	const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
	return (
		<div className={classes.section}>
			<GridContainer justify="center">
				<GridItem xs={12} sm={12} md={10}>
					<h2 className={classes.title}>Design Thinking Spike</h2>
					<h4 className={classes.title2}>
						Corso di introduzione al{" "}
						<Link href="/metodi/designthinking" color="secondary">
							Design Thinking
						</Link>
					</h4>
					<GridContainer justify="center">
						<GridItem xs={12} sm={12} md={4}>
							<img
								alt="Design Thinking Spike"
								title="Design Thinking Spike"
								src={DesignThinkingImage}
								className={navImageClasses}
							/>
						</GridItem>
						<GridItem xs={12} sm={12} md={4}>
							<List>
								<ListItem alignItems="flex-start">
									<ListItemAvatar>
										<AvTimer color="error" fontSize="large" />
									</ListItemAvatar>
									<ListItemText
										disableTypography
										secondary={
											<React.Fragment>
												<Typography
													component="p"
													variant="h6"
													color="textPrimary"
												>
													{"Durata: "}
												</Typography>
												<Typography
													component="p"
													variant="body1"
													className={classes.descrizioneVoce}
													color="textPrimary"
												>
													{"4 ore (sia in presenza che online)"}
												</Typography>
											</React.Fragment>
										}
									/>
								</ListItem>
								{/* <Divider variant="inset" component="li" />
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <EuroSymbol
                      className={classes.successColor}
                      fontSize="large"
                    />
                  </ListItemAvatar>
                  <ListItemText
                    disableTypography
                    secondary={
                      <div>
                        <Typography
                          component="div"
                          variant="h6"
                          color="textPrimary"
                        >
                          {"Costo: "}
                        </Typography>
                        <Typography
                          component="div"
                          variant="body1"
                          className={classes.descrizioneVoce}
                          color="textPrimary"
                        >
                          {"€350 + iva per gruppo"}
                        </Typography>
                      </div>
                    }
                  />
                </ListItem> */}
								<Divider variant="inset" component="li" />
								<ListItem alignItems="flex-start">
									<ListItemAvatar>
										<EmojiEvents
											className={classes.warningColor}
											fontSize="large"
										/>
									</ListItemAvatar>
									<ListItemText
										disableTypography
										secondary={
											<React.Fragment>
												<Typography
													component="div"
													variant="h6"
													color="textPrimary"
												>
													{"Obiettivi: "}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={classes.descrizioneVoceLista}
													color="textPrimary"
												>
													{
														"Promuovere una mentalità incentrata sull'utente (esterno o interno) e le sue necessità."
													}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={classes.descrizioneVoceLista}
													color="textPrimary"
												>
													{
														"Sviluppare idee, soluzioni e strategie innovative in qualsiasi ambito aziendale sbloccando il potenziale creativo di ogni persona."
													}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={classes.descrizioneVoceLista}
													color="textPrimary"
												>
													{
														"Stimolare team cross-funzionali a lavorare insieme verso un obiettivo comune."
													}
												</Typography>
											</React.Fragment>
										}
									/>
								</ListItem>

								<Divider variant="inset" component="li" />
							</List>
						</GridItem>
						<GridItem xs={12} sm={12} md={4}>
							<List>
								<ListItem alignItems="flex-start">
									<ListItemAvatar>
										<People fontSize="large" />
									</ListItemAvatar>
									<ListItemText
										disableTypography
										secondary={
											<React.Fragment>
												<Typography
													component="div"
													variant="h6"
													color="textPrimary"
												>
													{"Destinatari: "}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={classes.descrizioneVoceLista}
													color="textPrimary"
												>
													{
														"Tutti coloro che per lavoro o per crescita personale sono interessati a sviluppare capacità di problem solving fuori dagli schemi abituali, attraverso il lavoro di squadra, per il miglioramento dell’esperienza utente dei propri prodotti/servizi."
													}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={classes.descrizioneVoceLista}
													color="textPrimary"
												>
													{"Il gruppo ideale è di 6-12 partecipanti."}
												</Typography>
											</React.Fragment>
										}
									/>
								</ListItem>
								<Divider variant="inset" component="li" />
								<ListItem alignItems="flex-start">
									<ListItemAvatar>
										<ListAlt className={classes.infoColor} fontSize="large" />
									</ListItemAvatar>
									<ListItemText
										disableTypography
										secondary={
											<React.Fragment>
												<Typography
													component="div"
													variant="h6"
													color="textPrimary"
												>
													{"Programma: "}
												</Typography>
												{/* <Typography
													component="div"
													variant="body1"
													className={classes.descrizioneVoceLista}
													color="textPrimary"
												>
													{
														"Attività di riscaldamento propedeutica alla collaborazione."
													}
												</Typography> */}
												<Typography
													component="div"
													variant="body1"
													className={classes.descrizioneVoceLista}
													color="textPrimary"
												>
													{"Obiettivi e applicazioni del "}
													<Link href="/metodi/designthinking" color="secondary">
														Design Thinking
													</Link>
													{"."}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={classes.descrizioneVoceLista}
													color="textPrimary"
												>
													{
														"Definizione dei principi fondamentali: le 5 fasi del Design Thinking (Empathize, Define, Ideate, Prototype, Test)."
													}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={classes.descrizioneVoceLista}
													color="textPrimary"
												>
													{"Breve simulazione pratica delle 5 fasi."}
												</Typography>
											</React.Fragment>
										}
									/>
								</ListItem>
								<Divider variant="inset" component="li"></Divider>
								<ContattiShortComp align="right" />
							</List>
						</GridItem>
					</GridContainer>
				</GridItem>
			</GridContainer>
		</div>
	);
}
