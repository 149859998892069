import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import Collapse from "@material-ui/core/Collapse";
import CardMK from "../../../components/Card/Card.js";

import CardBody from "../../../components/Card/CardBody";
import Box from "@material-ui/core/Box";

import CardFooter from "../../../components/Card/CardFooter";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import teamWork from "../../../assets/img/teamWorkSmall.jpg";
import ButtonBase from "@material-ui/core/ButtonBase";

import styles from "../metodiStyle";

const useStyles = makeStyles(styles);
export default function CollaborazioneCard() {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 1800);

  const [expanded, setExpanded] = React.useState(false);
  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const classes = useStyles();

  return (
    <CardMK className={classes[cardAnimaton]}>
      <ButtonBase onClick={handleExpand}>
        <img
          title="Collaborazione e comunicazione"
          alt="Collaborazione e comunicazione"
          src={teamWork}
          className={classes.cardImage}
        />
      </ButtonBase>
      <Button
        color="primary"
        onClick={handleExpand}
        className={classes.bottonCollaps}
      >
        Collaborazione e comunicazione
        <ExpandMoreIcon
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
        />
      </Button>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardBody>
          <Typography
            variant="body1"
            // style={{ marginTop: "30px" }}
            component="div"
            align="justify"
            variant="body1"
            gutterBottom
            className={classes.descrizioneVoce}
          >
            <Box style={{ marginBottom: "1rem" }}>
              Se parliamo di un team motivato e responsabilizzato, che prende
              decisioni consapevoli ed opera per massimizzare il valore portato
              al cliente, a quale metodo ci stiamo riferendo? Lean, Agile o
              Design Thinking?
            </Box>
            <Box style={{ marginBottom: "1rem" }}>
              La risposta giusta è… che ogni risposta è giusta!
            </Box>
            <Box style={{ marginBottom: "1rem" }}>
              Infatti tutti questi metodi riconoscono l’importanza del
              coinvolgimento e della collaborazione tra le persone che seguono
              il processo, tanto da considerare il concetto di “team” come
              elemento caratterizzante il metodo stesso. I componenti del team,
              inoltre, condividono insieme la responsabilità di agire per
              perseguire la soddisfazione del cliente. Questo è possibile se la
              comunicazione tra le persone è un valore promosso, riconosciuto e
              agevolato da tutti, sia all’interno dell’azienda che verso
              l’esterno.
            </Box>
          </Typography>
        </CardBody>
        <CardFooter className={classes.cardFooter}></CardFooter>
      </Collapse>
    </CardMK>
  );
}
