import { container, title } from "assets/jss/material-kit-react.js";

const serviceStyle = {
  section: {
    background: "radial-gradient(ellipse at center,#585858 0,#232323 100%)",
    padding: 0,
    textAlign: "center",
    marginAuto: 0,
    marginBottom: 0,
  },
  title: {
    ...title,
    marginBottom: "5px",
    marginTop: "10px",
    minHeight: "32px",
    textDecoration: "none",
    color: "#eee",
  },
  // container,
  textCenter: { textAlign: "center" },
  description: { color: "#ddd" },
  card: { width: "95%", marginBottom: 20, marginTop: 20, align: "center" },
  gridTop: { marginBottom: 0, marginTop: 0, align: "center" },

  textContainer: {
    // display: "flex",
    padding: "40px 40px",
    alignItems: "center",
    justifyContent: "center",
  },
  imageBanner: {
    width: "100%",
    minHeight: "500px",

    clipPath: "polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)",
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)",
  },
  cardHeader: {
    width: "auto",
    textAlign: "center",
    marginLeft: "20px",
    marginRight: "20px",
    marginTop: "-40px",
    padding: "20px 0",
    marginBottom: "15px",
    "&:hover": {
      background: "linear-gradient(60deg, #8e24aa, #ab47bc)",
      cursor: "pointer",
    },
  },
  cardIconHover: {
    "&:hover": { color: "#89229b" },
  },
  cardBottonButton: {
    color: "#ddd",
    background: "linear-gradient(60deg, #8e24aa, #ab47bc)",

    "&:hover": {
      color: "#011",
    },
  },
  socialIcons: {
    maxWidth: "24px",
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
  },
  divider: {
    marginTop: "1rem",
    marginBottom: "1rem",
    textAlign: "center",
  },
  cardFooter: {
    paddingTop: "0rem",
    border: "0",
    borderRadius: "6px",
    justifyContent: "center !important",
  },
  socialLine: {
    marginTop: "1rem",
    textAlign: "center",
    padding: "0",
  },
  inputIconsColor: {
    color: "#495057",
  },
  headerIcon: {
    color: "#98ddaa",
    fontSize: "50px",
  },
  bullet: {
    // display: "inline-block",
    margin: "2px 2px",
    transform: "scale(0.8)",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: ["transform", "2s"],
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  bottonCollaps: {
    textTransform: "none",
    marginLeft: "1rem",
    marginBottom: "1rem",
  },
  cardH4: {
    padding: "0 0.5rem",
    fontWeight: 500,
    fontSize: "1rem",
  },
};

export default serviceStyle;
