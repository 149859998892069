import React, { useRef, useState } from "react";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";

import Link from "@material-ui/core/Link";
import classNames from "classnames";

// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Typography from "@material-ui/core/Typography";
import MuiAccordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styles from "../serviziStyle";

const useStyles = makeStyles(styles);
const Accordion = withStyles({
  expanded: {},
})(MuiAccordion);

import heroInnovazioneImages from "../../../assets/img/innovazione.jpg";

export default function InnovazioneEsempi() {
  const classes = useStyles();
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

  const [expanded, setExpanded] = useState("");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <div className={classes.sectionAlternate}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={5}>
          <h2 className={classes.titleAlternate}>Gestire l'innovazione</h2>
          <h3 className={classes.subTitleAlternate}>Esempi di intervento</h3>
          <Accordion
            className={classes.accordionMargin}
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.accorditionHeading}
            >
              <Typography
                align="left"
                variant="h6"
                className={classes.accordionTitle}
              >
                Azienda snella senza traumi
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                align="left"
                variant="body1"
                className={classes.title}
              >
                Hai provato a introdurre pratiche Lean nella tua catena di
                produzione ma i risultati non sono stati quelli che ti
                aspettavi?
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography align="justify" display="block">
                Se pensi che la tua azienda non possa trasformarsi nella Toyota
                semplicemente perché le viene imposto, noi siamo d’accordo con
                te. A volte ci si concentra sugli obiettivi del cambiamento
                sottovalutando l’importanza del percorso necessario per il loro
                raggiungimento.
                <br />
                Noi possiamo sostenerti in un progetto di innovazione per
                introdurre le metodologie e la filosofia Lean nella tua azienda
                utilizzando pratiche Agile.
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography align="justify">In che modo?</Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography align="justify">
                Il nostro intervento consiste nel partecipare all’azione del tuo
                team di progetto per realizzare insieme, con un approccio Agile,
                la trasformazione Lean in modo incrementale, passo passo, senza
                dogmi.
                <br />
                L'approccio Agile aiuta a coinvolgere il tuo team di progetto
                fin dalla fase di ideazione e valorizza la tua esperienza e il
                tuo “saper fare”, superando la resistenza al cambiamento,
                promuovendo la creatività e la produzione di idee e soluzioni
                originali e riducendo al minimo la probabilità di fallimento del
                progetto.
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography align="justify">
                Qualora, poi, emergesse la necessità di digitalizzare processi o
                servizi, siamo in grado di supportarti nella scelta della
                tecnologia più adeguata alle tue esigenze, sia come soluzione
                open source. che di mercato (prodotti o servizi cloud). Possiamo
                sviluppare anche soluzioni dedicate, qualora questa fosse la
                scelta più strategica per te.
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography variant="subtitle2" display="block">
                <Link href="/metodi/agile" color="secondary">
                  Agile
                </Link>
                {" + "}
                <Link href="/metodi/lean" color="secondary">
                  Lean
                </Link>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className={classes.accordionMargin}
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
              className={classes.accorditionHeading}
            >
              <Typography
                align="left"
                variant="h6"
                className={classes.accordionTitle}
              >
                Governare la digitalizzazione
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                align="left"
                variant="body1"
                className={classes.title}
              >
                Hai l’impressione che la tua azienda fatichi a stare al passo
                dell’innovazione digitale? Sei disorientato dalla frenetica
                proliferazione di nuove soluzioni informatiche e di nuovi
                servizi cloud? Ritieni di spendere troppo tempo ed energie nella
                ricerca dei giusti strumenti informatici senza ottenere un reale
                vantaggio?
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography align="justify" display="block">
                Quasi mai l’acquisto, o l’imposizione, di una soluzione
                informatica preconfezionata raggiunge l’obiettivo di creare il
                valore promesso.
                <br />
                Noi possiamo aiutarti a massimizzare i vantaggi di un progetto
                di innovazione digitale accompagnandoti nella sua realizzazione
                in modalità Agile.
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography align="justify">In che modo?</Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography align="justify">
                Il nostro intervento consiste nel partecipare all’azione di un
                tuo team di progetto di digitalizzazione, mettendo a tuo
                servizio la nostra esperienza sia sul metodo Agile che rispetto
                agli strumenti tecnologici. <br />
                Con l'Agile lo svolgimento del progetto procede per cicli, con
                un coinvolgimento diretto e proattivo del team di sviluppo fin
                dalle battute iniziali.
                <br />
                Il cambiamento in questo modo verrà dall'interno, in modo
                incrementale, riducendo al minimo le barriere al cambiamento e
                focalizzandosi sugli obiettivi realmente importanti per te.
                <br />
                <br />
                Qualunque sarà la via che avrai scelto per la digitalizzazione
                della tua azienda (software di mercato, piattaforme cloud o
                sviluppo diretto), noi ti seguiremo.
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography variant="subtitle2" display="block">
                <Link href="/metodi/agile" color="secondary">
                  Agile
                </Link>
                {" + Digitalizzazione"}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </GridItem>
        <GridItem xs={12} sm={12} md={1}></GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <img
            alt="Consulenza per introdurre l'innovazione"
            title="Consulenza per introdurre l'innovazione"
            src={heroInnovazioneImages}
            className={navImageClasses}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
