import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Chat from "@material-ui/icons/Chat";
import Build from "@material-ui/icons/Build";
import SignalCellularAltIcon from "@material-ui/icons/SignalCellularAlt";
import SearchIcon from "@material-ui/icons/Search";
import WarningIcon from "@material-ui/icons/Warning";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import styles from "../chiSiamoPageStyle";

import BlurLinearIcon from "@material-ui/icons/BlurLinear";
import FlipCameraAndroidIcon from "@material-ui/icons/FlipCameraAndroid";
import ScreenShareIcon from "@material-ui/icons/ScreenShare";
import WbIncandescentIcon from "@material-ui/icons/WbIncandescent";
import Divider from "@material-ui/core/Divider";

import ChiSiamoWebyliaImage from "../../../assets/img/ChiSiamoWebyliaImage.jpg";

const useStyles = makeStyles(styles);

export default function WebyliaSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer className={classes.textContainer}>
        <GridItem xs={12} sm={12} md={8}>
          {" "}
          <h4 className={classes.description}>
            Webylia è un network di liberi professionisti indipendenti.
            <br />
            Lavoriamo in team per offrire alle piccole e medie imprese una
            consulenza integrata di innovazione sostenibile.
          </h4>
        </GridItem>
      </GridContainer>
      <h2 className={classes.title}>Perchè sceglierci</h2>
      <h4 className={classes.description}></h4>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={3}>
          <List>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <SearchIcon className={classes.infoColor} fontSize="large" />
              </ListItemAvatar>
              <ListItemText
                primary="TRASPARENZA"
                secondary={
                  <Typography
                    component="div"
                    variant="body1"
                    className={classes.descrizioneVoce}
                    color="textPrimary"
                  >
                    Gli interventi che proponiamo hanno tempi e costi certi.
                    Adottiamo un approccio operativo flessibile e dinamico per
                    il raggiungimento degli obiettivi che vengono tenuti
                    costantemente sotto controllo attraverso il confronto e la
                    condivisione delle scelte con il cliente.
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <List>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <SignalCellularAltIcon
                  className={classes.successColor}
                  fontSize="large"
                />
              </ListItemAvatar>
              <ListItemText
                primary="GRADUALITÀ DEGLI INTERVENTI"
                secondary={
                  <Typography
                    component="div"
                    variant="body1"
                    className={classes.descrizioneVoce}
                    color="textPrimary"
                  >
                    Proponiamo i nostri servizi con un metodo Agile: un
                    approccio incrementale per un miglioramento continuo che
                    stimoli soluzioni creative e generi innovazione in un
                    contesto di collaborazione e integrazione.
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <List>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <WarningIcon
                  className={classes.warningColor}
                  fontSize="large"
                />
              </ListItemAvatar>
              <ListItemText
                primary="RIDUZIONE DEL RISCHIO"
                secondary={
                  <Typography
                    component="div"
                    variant="body1"
                    className={classes.descrizioneVoce}
                    color="textPrimary"
                  >
                    La trasparenza e la gradualità degli interventi danno
                    l'opportunità di gestire in modo incrementale l'impegno del
                    cliente e di verificare ad ogni passo il reale valore
                    apportato, riducendo al minimo il rischio di sprecare
                    risorse o mancare gli obiettivi.
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </GridItem>
      </GridContainer>
    </div>
  );
}
