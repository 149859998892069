import {
  container,
  title,
  successColor,
  warningColor,
  dangerColor,
  infoColor,
  typography,
} from "../../assets/jss/material-kit-react.js";

import imagesStyle from "../../assets/jss/material-kit-react/imagesStyles.js";

const serviziStyle = {
  descrizioneVoce: {
    fontSize: "18px",
    fontWeight: "300",
  },
  successColor: {
    color: successColor,
  },
  warningColor: {
    color: warningColor,
  },
  dangerColor: {
    color: dangerColor,
  },
  infoColor: {
    color: infoColor,
  },
  descrizioneVoceLista: {
    fontSize: "16px",
    padding: "10px 0px",
  },
  leggiDiPiuVoceLista: {
    fontSize: "12px",
    padding: "8px 0px",
    textAlign: "right",
  },
  container: {
    maxWidth: "3000px",
    padding: 0,
    zIndex: "12",
    color: "#FFFFFF",
    ...container,
  },
  sectionTop: {
    padding: "80px 10px 80px 10px",
    textAlign: "center",
  },
  section: {
    padding: "70px 10px 20px 10px",
    textAlign: "center",
  },
  sectionAlternate: {
    padding: "70px 10px 20px 10px",
    textAlign: "center",
    background: "radial-gradient(ellipse at center,#585858 0,#232323 100%)",
  },

  titleAlternate: {
    ...title,
    color: "#fff",
    // display: "inline-block",
    // position: "relative",
    marginTop: "0px",
    marginBottom: "20px",
    minHeight: "32px",
    textDecoration: "none",
  },
  subTitleAlternate: {
    ...title,
    color: "#fff",
    fontWeight: 500,
    // display: "inline-bloc
    // position: "relative",
    marginTop: "0px",
    marginBottom: "30px",
  },
  parallaxContainer: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container,
  },
  parallaxTitle: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    // marginBottom: "5px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none",
  },
  parallaxSubTitle: {
    // ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "0px",
    // minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none",
  },
  profile: {
    textAlign: "center",
    "& img": {
      maxWidth: "160px",
      width: "100%",
      margin: "0 auto",
      transform: "translate3d(0, -50%, 0)",
    },
  },
  description: {
    margin: "1.071rem auto 0",
    maxWidth: "600px",
    color: "#999",
    textAlign: "center !important",
  },
  name: {
    marginTop: "-80px",
  },
  ...imagesStyle,
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
  title: {
    ...title,
    // display: "inline-block",
    // position: "relative",
    marginTop: "30px",
    marginBottom: "4px",
    minHeight: "32px",
    textDecoration: "none",
  },
  title2: {
    marginBottom: "3em",
  },
  sectionTitle: {
    ...title,
    // display: "inline-blo
    // position: "relative"
    marginTop: "0px",
    marginBottom: "20px",
    minHeight: "32px",
    textDecoration: "none",
  },
  sectionSubTitle: {
    ...title,
    fontWeight: 500,
    // display: "inline-bloc
    // position: "relative",
    marginTop: "0px",
    marginBottom: "30px",
  },
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#999",
  },
  navWrapper: {
    margin: "20px auto 50px auto",
    textAlign: "center",
  },
  accorditionHeading: {
    fontSize: 15,
    backgroundColor: "#eee",
  },
  accordionTitle: {
    ...title,
    marginTop: "4px",
    marginBottom: "4px",
  },
  accordionMargin: { marginBottom: 5 },
  paddingImage: { paddingTop: 20 },
};

export default serviziStyle;
