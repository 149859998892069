import React, { useEffect, useRef } from "react";

import Header from "../../components/Header/Header";
import HeaderLinks from "../../components/Header/HeaderLinks";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import MetodiBreadcrumbs from "./MetodiBreadcrumbs";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import CardMedia from "@material-ui/core/CardMedia";

import styles from "./metodiStyle.js";
import LeanImage from "../../assets/img/hero-leanMedium.jpg";
import leanPrincipi from "../../assets/img/leanPrincipi.png";
import LeanTaiichiOhono from "../../assets/img/LeanTaiichiOhono.jpg";
import leanWordCloud from "../../assets/img/leanWordCloud.png";

const useStyles = makeStyles(styles);

export default function LeanPage(props) {
  const topRef = useRef(null);
  const executeScrollToTop = () => {
    topRef.current.scrollIntoView();
  };
  useEffect(executeScrollToTop);

  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <div ref={topRef}>
      <Header
        color="transparent"
        brand="Webylia"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "white",
        }}
      />
      <Parallax xsmall filter image={LeanImage}>
        <div className={classes.parallaxContainer}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <h1 className={classes.parallaxTitle}>Lean</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <MetodiBreadcrumbs />
        <GridContainer
          justify="center"
          spacing={8}
          alignItems="center"
          style={{ textAlign: "center" }}
        >
          <GridItem xs={false} sm={false} lg={1}></GridItem>
          <GridItem xs={12} sm={12} lg={3}>
            <img
              title="principi Lean"
              alt="principi Lean"
              src={leanPrincipi}
              className={classes.imageMax}
            />
          </GridItem>
          <GridItem xs={12} sm={12} lg={6}>
            <Typography
              variant="body1"
              // style={{ marginTop: "30px" }}
              component="div"
              align="justify"
              variant="body1"
              gutterBottom
              className={classes.descrizioneVoce}
            >
              <Box style={{ marginBottom: "1rem" }}>
                La filosofia Lean – nota anche come Lean Thinking, o Pensiero
                Snello – è una filosofia di management che mira all’
                <Box fontWeight="fontWeightMedium" display="inline">
                  abbattimento degli sprechi
                </Box>{" "}
                per migliorare l’
                <Box fontWeight="fontWeightMedium" display="inline">
                  efficienza
                </Box>{" "}
                dei processi e la{" "}
                <Box fontWeight="fontWeightMedium" display="inline">
                  qualità
                </Box>{" "}
                dei prodotti attraverso il livellamento e la standardizzazione
                delle operazioni e il coinvolgimento delle persone nelle
                attività di miglioramento.
              </Box>
              <Box style={{ marginBottom: "1rem" }}>
                La filosofia Lean può essere applicata a{" "}
                <Box fontWeight="fontWeightMedium" display="inline">
                  qualsiasi settore
                </Box>{" "}
                industriale, ma anche ai servizi e alle funzioni non produttive,
                come, ad esempio, le attività alberghiere, la pubblica
                amministrazione, la sanità oppure le funzioni amministrative.
              </Box>
              <Box style={{ marginBottom: "1rem" }}>
                L’obiettivo generale della filosofia Lean è quello di creare{" "}
                <Box fontWeight="fontWeightMedium" display="inline">
                  valore per il cliente
                </Box>
                .
              </Box>
              <Box>
                I 5 principi chiave intorno a cui si sviluppa il Lean Thinking
                sono:
              </Box>
              <Box style={{ marginBottom: "2rem", paddingLeft: "0.5rem" }}>
                {bull} definire chiaramente il valore di uno specifico prodotto
                o servizio dal punto di vista del cliente;
                <br />
                {bull} identificare tutte le attività del processo di produzione
                di un bene, o di erogazione di un servizio, e stabilire se
                apportano valore al cliente oppure no;
                <br />
                {bull} organizzare le varie fasi del processo in modo che il
                flusso dei materiali e delle informazioni scorra senza
                interruzioni;
                <br />
                {bull} far sì che le lavorazioni e le forniture si attivino solo
                in risposta all’effettiva domanda del mercato;
                <br />
                {bull} puntare alla perfezione attraverso cicli continui di
                miglioramento.
              </Box>
            </Typography>
          </GridItem>
        </GridContainer>
        {/* <GridContainer justify="center" style={{ padding: "0px 1116px" }}>
          <GridItem xs={12} sm={12} md={6}></GridItem>
        </GridContainer> */}
        <div className={classes.stratiSection}>
          <GridContainer
            className={classes.gridTop}
            justify="center"
            spacing={8}
            alignItems="center"
          >
            {/* <GridItem xs={false} sm={false} md={1}></GridItem> */}
            <GridItem xs={12} sm={12} lg={6}>
              <Typography
                variant="body1"
                // style={{ marginTop: "30px" }}
                component="div"
                align="justify"
                variant="body1"
                gutterBottom
                className={classes.descrizioneVoceDark}
              >
                <Box style={{ marginBottom: "1rem" }}>
                  La Lean propone alcuni strumenti per raggiungere i suoi
                  obiettivi.{" "}
                </Box>
                <Box style={{ marginBottom: "1rem" }}>
                  <Box fontWeight="fontWeightBold" display="inline">
                    Value Stream Map:{" "}
                  </Box>
                  rappresentazione dei flussi dei materiali e delle informazioni
                  attraverso tutta l’organizzazione che individua le attività a
                  valore per il cliente.
                </Box>
                <Box style={{ marginBottom: "1rem" }}>
                  <Box fontWeight="fontWeightBold" display="inline">
                    Just-in-Time:{" "}
                  </Box>
                  metodo di gestione che prevede l'avvio della produzione solo
                  nel momento in cui si manifesta la domanda e in base alle
                  quantità richieste.
                </Box>
                <Box style={{ marginBottom: "1rem" }}>
                  <Box fontWeight="fontWeightBold" display="inline">
                    Kanban:{" "}
                  </Box>
                  tecnica che rende possibile il “pull flow”, ossia il flusso
                  dei materiali tirato dai consumi. Il kanban è un “segnale” -
                  costituito, ad esempio, da un cartellino fisico o da un
                  contenitore vuoto - che autorizza la produzione, l’acquisto o
                  la movimentazione dei materiali.
                </Box>
                <Box style={{ marginBottom: "1rem" }}>
                  <Box fontWeight="fontWeightBold" display="inline">
                    Total Production Maintenance (TPM):{" "}
                  </Box>
                  attribuisce la competenza per le attività di manutenzione
                  delle postazioni di lavoro agli stessi lavoratori, dando loro
                  le competenze e gli strumenti necessari per far fronte anche a
                  qualsiasi anomalia, cercando di prevenire i problemi invece di
                  correggerli e massimizzando la disponibilità del team di
                  produzione e dei macchinari.
                </Box>
                <Box style={{ marginBottom: "1rem" }}>
                  <Box fontWeight="fontWeightBold" display="inline">
                    SMED (Single Minute Exchange of Dies):{" "}
                  </Box>
                  è una metodologia utilizzata per ridurre il tempo impiegato
                  per cambiare gli attrezzaggi nelle macchine. L'idea di base è
                  quella di cambiare gli strumenti di produzione in meno di 10
                  minuti, cercando di minimizzare il tempo speso per il
                  passaggio da un prodotto all'altro o il tempo di manutenzione
                  della macchina. È fondamentale per potersi permettere di
                  ridurre il dimensionamento dei lotti di produzione.
                </Box>
                <Box style={{ marginBottom: "1rem" }}>
                  <Box fontWeight="fontWeightBold" display="inline">
                    Metodo delle 5S:{" "}
                  </Box>
                  è l’attuazione del miglioramento continuo nell'area di lavoro
                  per facilitare il flusso di materiali e persone, riducendo
                  così errori e tempi. Le 5S in giapponese corrispondono a
                  organizzazione, ordine, pulizia, standardizzazione e
                  disciplina.
                </Box>
                <Box style={{ marginBottom: "1rem" }}>
                  <Box fontWeight="fontWeightBold" display="inline">
                    Produzione per celle:{" "}
                  </Box>
                  è un modo per organizzare le postazioni di lavoro lungo il
                  processo di produzione per un prodotto specifico o per
                  prodotti simili. I relativi macchinari, attrezzature e
                  operatori sono raggruppati in celle (o zone) a forma di U.
                </Box>
                <Box style={{ marginBottom: "1rem" }}>
                  <Box fontWeight="fontWeightBold" display="inline">
                    Kaizen ("cambiare in meglio"):{" "}
                  </Box>
                  l’insieme delle tecniche che permettono ad ogni individuo e ad
                  ogni organizzazione di migliorare con continuità nel tempo.
                </Box>
                <Box style={{ marginBottom: "1rem" }}>
                  <Box fontWeight="fontWeightBold" display="inline">
                    Poka-Yoke ("a prova di errore"):{" "}
                  </Box>
                  ha l’obiettivo di creare meccanismi semplici, per far sì che
                  le operazioni non possano che essere eseguite nel modo
                  corretto.
                </Box>
                <Box style={{ marginBottom: "1rem" }}>
                  <Box fontWeight="fontWeightBold" display="inline">
                    Visual aids e visual management:{" "}
                  </Box>
                  gestione a vista dei processi che si basa sulla piena
                  visibilità del loro stato di avanzamento. L’obiettivo è
                  rendere immediato il controllo dello stato e dei risultati del
                  processo.
                </Box>
              </Typography>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <CardMedia
                // className={classes.imageBanner}
                component="img"
                alt="strumenti Lean"
                // height="200"
                image={leanWordCloud}
                title="strumenti Lean"
              />
            </GridItem>
          </GridContainer>
        </div>

        <GridContainer
          justify="center"
          spacing={8}
          alignItems="center"
          style={{ textAlign: "center" }}
        >
          <GridItem xs={false} sm={false} lg={1}></GridItem>
          <GridItem xs={12} sm={12} lg={3}>
            <img
              title="Taiichi Ohno"
              alt="Taiichi Ohno"
              src={LeanTaiichiOhono}
              className={classes.imageMax}
            />
          </GridItem>
          <GridItem xs={12} sm={12} lg={6}>
            <h3>Breve storia della Lean production</h3>
            <Typography
              variant="body1"
              // style={{ marginTop: "30px" }}
              component="div"
              align="justify"
              variant="body1"
              gutterBottom
              className={classes.descrizioneVoce}
            >
              <Box style={{ marginBottom: "1rem" }}>
                Subito dopo la fine della seconda guerra mondiale gli ingegneri
                Eiji Toyoda e Taiichi Ohno della Toyota si resero conto che il
                metodo di produzione di massa degli Stati Uniti non era
                sostenibile per la situazione in cui si trovava il Giappone.{" "}
              </Box>
              <Box style={{ marginBottom: "1rem" }}>
                Per questo idearono un nuovo sistema di produzione, il Toyota
                Production System (TPS), il cui obiettivo primario rimane ancora
                oggi eliminare sprechi e attività che non aggiungono valore al
                cliente, migliorando la produttività.{" "}
              </Box>
              <Box style={{ marginBottom: "1rem" }}>
                Nel loro influente libro del 1990,{" "}
                <Box fontStyle="italic" display="inline">
                  The Machine that Changed the World
                </Box>
                , Womack, Jones e Roos hanno introdotto il termine "lean
                production" come sinonimo di TPS.
              </Box>
              <Box style={{ marginBottom: "1rem" }}>
                Come documentarono Womack e i suoi colleghi il coinvolgimento e
                la collaborazione tra le persone, siano essi dipendenti,
                fornitori o clienti, è il “segreto” per massimizzare il valore
                prodotto e poter contare su di un sistema produttivo efficiente
                e resiliente.
              </Box>
              <Box style={{ marginBottom: "1rem" }}>
                Nel corso degli ultimi vent'anni l’applicazione della Lean è
                andata ben oltre il settore dell’automotive, con innumerevoli
                applicazioni non solo nell'ambito della produzione, ma anche dei
                servizi.
              </Box>
            </Typography>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
