import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import ContattiShortComp from "../../Contatti/ContattiShortComp";

// @material-ui/icons
import AvTimer from "@material-ui/icons/AvTimer";
import EuroSymbol from "@material-ui/icons/EuroSymbol";
import EmojiEvents from "@material-ui/icons/EmojiEvents";
import People from "@material-ui/icons/People";
import ListAlt from "@material-ui/icons/ListAlt";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Link from "@material-ui/core/Link";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";

import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";

// import LeanThinkingImage from "../../../assets/img/LeanThinkingImage.jpeg";
import LeanThinkingImage from "../../../assets/img/LeanThinkingImage2.jpg";
import styles from "../pacchettiStyle.js";

const useStyles = makeStyles(styles);

export default function LeanThinkingSection() {
	const [expanded, setExpanded] = React.useState(false);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};
	const classes = useStyles();
	const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
	return (
		<div className={classes.section}>
			<GridContainer justify="center">
				<GridItem xs={12} sm={12} md={10}>
					<h2 className={classes.title}>Lean Thinking</h2>
					<GridContainer justify="center">
						<GridItem xs={12} sm={12} md={4}>
							<img
								alt="Lean Thinking"
								title="Lean Thinking"
								src={LeanThinkingImage}
								className={navImageClasses}
							/>
						</GridItem>
						<GridItem xs={12} sm={12} md={4}>
							<List>
								<ListItem alignItems="flex-start">
									<ListItemAvatar>
										<AvTimer color="error" fontSize="large" />
									</ListItemAvatar>
									<ListItemText
										disableTypography
										secondary={
											<React.Fragment>
												<Typography
													component="div"
													variant="h6"
													className={classes.inline}
													color="textPrimary"
												>
													{"Durata: "}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={classes.descrizioneVoce}
													color="textPrimary"
												>
													{"8 ore"}
												</Typography>
											</React.Fragment>
										}
									/>
								</ListItem>
								<Divider variant="inset" component="li" />
								{/* <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <EuroSymbol
                      className={classes.successColor}
                      fontSize="large"
                    />
                  </ListItemAvatar>
                  <ListItemText
                    disableTypography
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="div"
                          variant="h6"
                          className={classes.inline}
                          color="textPrimary"
                        >
                          {"Costo: "}
                        </Typography>
                        <Typography
                          component="div"
                          variant="body1"
                          className={classes.descrizioneVoce}
                          color="textPrimary"
                        >
                          {"€700 + iva per gruppo"}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" /> */}
								<ListItem alignItems="flex-start">
									<ListItemAvatar>
										<EmojiEvents
											className={classes.warningColor}
											fontSize="large"
										/>
									</ListItemAvatar>
									<ListItemText
										disableTypography
										secondary={
											<React.Fragment>
												<Typography
													component="div"
													variant="h6"
													// className={classes.inline}
													color="textPrimary"
												>
													{"Obiettivi: "}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={classes.descrizioneVoceLista}
													color="textPrimary"
												>
													{
														"Comprendere la centralità del punto di vista del Cliente nella definizione di valore di un processo o di un prodotto."
													}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={classes.descrizioneVoceLista}
													color="textPrimary"
												>
													{
														"Scoprire i benefici della sincronizzazione tra ritmo di produzione e domanda di mercato apportati dalle logiche Just-in-Time."
													}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={classes.descrizioneVoceLista}
													color="textPrimary"
												>
													{
														"Contestualizzare i concetti Lean nella realtà aziendale."
													}
												</Typography>
											</React.Fragment>
										}
									/>
								</ListItem>

								<Divider variant="inset" component="li" />
							</List>
						</GridItem>
						<GridItem xs={12} sm={12} md={4}>
							<List>
								<ListItem alignItems="flex-start">
									<ListItemAvatar>
										<People fontSize="large" />
									</ListItemAvatar>
									<ListItemText
										disableTypography
										secondary={
											<React.Fragment>
												<Typography
													component="div"
													variant="h6"
													// className={classes.inline}
													color="textPrimary"
												>
													{"Destinatari: "}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={classes.descrizioneVoceLista}
													color="textPrimary"
												>
													{
														"Chiunque in azienda sia interessato a migliorare la capacità dei processi aziendali di produrre valore per il cliente."
													}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={classes.descrizioneVoceLista}
													color="textPrimary"
												>
													{
														"Gli imprenditori e i responsabili di funzione che intendono massimizzare la capacità di servizio, la qualità e l'economicità dei processi aziendali."
													}
												</Typography>
											</React.Fragment>
										}
									/>
								</ListItem>
								<Divider variant="inset" component="li" />
								<ListItem alignItems="flex-start">
									<ListItemAvatar>
										<ListAlt className={classes.infoColor} fontSize="large" />
									</ListItemAvatar>
									<ListItemText
										disableTypography
										secondary={
											<React.Fragment>
												<Typography
													component="div"
													variant="h6"
													// className={classes.inline}
													color="textPrimary"
												>
													{"Programma: "}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={classes.descrizioneVoceLista}
													color="textPrimary"
												>
													{"Introduzione alla "}
													<Link href="/metodi/lean" color="secondary">
														filosofia Lean
													</Link>
													{"."}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={classes.descrizioneVoceLista}
													color="textPrimary"
												>
													{
														"Presentazione dei principi fondamentali della filosofia Lean."
													}
												</Typography>

												{!expanded && (
													<IconButton
														className={classes.leggiDiPiuVoceLista}
														onClick={handleExpandClick}
														aria-label="show more"
													>
														...leggi di più
													</IconButton>
												)}

												<Collapse in={expanded} timeout="auto" unmountOnExit>
													<Typography
														component="div"
														variant="body1"
														className={classes.descrizioneVoceLista}
														color="textPrimary"
													>
														{"Classificazione degli sprechi in ottica Lean."}
													</Typography>
													<Typography
														component="div"
														variant="body1"
														className={classes.descrizioneVoceLista}
														color="textPrimary"
													>
														{
															"Introduzione del concetto di produzione Just-in-Time."
														}
													</Typography>
													<Typography
														component="div"
														variant="body1"
														className={classes.descrizioneVoceLista}
														color="textPrimary"
													>
														{
															"Riepilogo dei concetti esposti nel corso della giornata e discussione di gruppo circa l'applicabilità della filosofia Lean in azienda."
														}
													</Typography>
													<Typography
														component="div"
														variant="body1"
														className={classes.descrizioneVoceLista}
														color="textPrimary"
													>
														<IconButton
															className={classes.leggiDiPiuVoceLista}
															onClick={handleExpandClick}
															aria-expanded={expanded}
															aria-label="show more"
														>
															...leggi di meno
														</IconButton>
													</Typography>
												</Collapse>
											</React.Fragment>
										}
									/>
								</ListItem>
								<Divider variant="inset" component="li" />
								<ContattiShortComp classes={classes} align="right" />
							</List>
						</GridItem>
					</GridContainer>
				</GridItem>
			</GridContainer>
		</div>
	);
}
