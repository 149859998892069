import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Collapse from "@material-ui/core/Collapse";
import CardMK from "../../../components/Card/Card.js";

import CardBody from "../../../components/Card/CardBody";
import Box from "@material-ui/core/Box";

import CardFooter from "../../../components/Card/CardFooter";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import kaizen from "../../../assets/img/kaizenSmall.jpg";
import ButtonBase from "@material-ui/core/ButtonBase";

import styles from "../metodiStyle";

const useStyles = makeStyles(styles);
export default function CiclicitàCard() {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 1800);

  const [expanded, setExpanded] = React.useState(false);
  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const classes = useStyles();

  return (
    <CardMK className={classes[cardAnimaton]}>
      {/* <Button className={classes.headerIcon} onClick={handleExpand}> */}
      <ButtonBase
        onClick={handleExpand}
        // className={classes.bottonCollaps}
      >
        <img
          title="Miglioramento continuo"
          alt="Miglioramento continuo"
          src={kaizen}
          className={classes.cardImage}
        />
      </ButtonBase>
      <Button
        color="primary"
        onClick={handleExpand}
        className={classes.bottonCollaps}
      >
        Ciclicità e miglioramento continuo
        <ExpandMoreIcon
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
        />
      </Button>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardBody>
          <Typography
            variant="body1"
            // style={{ marginTop: "30px" }}
            component="div"
            align="justify"
            variant="subtitle1"
            gutterBottom
            className={classes.descrizioneVoce}
          >
            <Box style={{ marginBottom: "1rem" }}>
              L’idea che sia un errore sedersi sugli allori e che solo puntando
              alla perfezione si possa eccellere pone radici profonde nella
              cultura umana. Il fatto che questo percorso non finisca mai, ma
              debba essere un ciclo in cui ripercorri i tuoi passi per un
              miglioramento continuo è il centro del Kaizen (parte della Lean)
              che fin dagli anni '60 influenzò il mondo della produzione e della
              gestione della qualità. Fu recepita dalle normative ISO 9001 fin
              dalla prima stesura, ma prendendo sempre più forza proprio nel
              concetto di ciclicità, attraverso la ISO 9001:2015 fino alla ISO
              56000.
            </Box>
            <Box style={{ marginBottom: "1rem" }}>
              Agile si differenzia da tecniche tradizionali di gestione progetti
              (project management) proprio per l’atteggiamento ciclico, sia
              nello sviluppo di progetti, che ridistribuisce le fasi di analisi,
              progettazione, costruzione e test per cicli, che in un
              ripercorrere continuamente le metodologie e le prassi adottate per
              continuare a migliorarle. Il metodo Agile più diffuso è Scrum che,
              come fondamento imprescindibile, ha la scomposizione delle fasi di
              lavoro in quelli che vengono definiti Sprint, ossia intervalli di
              tempo della durata da una a quattro settimane.
            </Box>
            <Box style={{ marginBottom: "1rem" }}>
              Il Design Thinking è un percorso ciclico per identificare con
              esattezza le esigenze e sperimentare prototipi in modo da
              individuare in maniera pragmatica soluzioni che a volte possono
              essere inaspettate.
            </Box>
            <Box style={{ marginBottom: "1rem" }}>
              Per farlo deve ripercorrere più volte la strada, ma ogni passaggio
              permette di acquisire una maggiore conoscenza e nuove prospettive
              che possono derivare da mutamenti in atto nel mercato e nelle
              esigenze degli utenti.
            </Box>
          </Typography>
        </CardBody>
        <CardFooter className={classes.cardFooter}></CardFooter>
      </Collapse>
    </CardMK>
  );
}
