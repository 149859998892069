import React, { useEffect, useRef } from "react";
import Header from "../../components/Header/Header";
import HeaderLinks from "../../components/Header/HeaderLinks";

import TeamSection from "./Sections/TeamSection";
import WebyliaSection from "./Sections/WebyliaSection";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components

import Slide from "@material-ui/core/Slide";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "../../components/Parallax/Parallax.js";

import Divider from "@material-ui/core/Divider";

import styles from "./chiSiamoPageStyle.js";
import heroChiSiamoImage from "../../assets/img/hero-chiSiamoImage4Medium.jpg";
const useStyles = makeStyles(styles);

export default function ChisiamoPage(props) {
  const topRef = useRef(null);

  const executeScrollToTop = () => {
    topRef.current.scrollIntoView();
  };
  useEffect(executeScrollToTop);

  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div ref={topRef}>
      <Header
        color="transparent"
        // routes={dashboardRoutes}
        brand="Webylia"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "white",
        }}
        // {...rest}
      />
      <Parallax
        medium
        filter
        image={heroChiSiamoImage}
        alt="consulenza aziendale nel Triveneto"
      >
        <div className={classes.parallaxContainer}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Fade in timeout={3000}>
                <h1 className={classes.parallaxTitle}>Webylia</h1>
              </Fade>
              <Slide in direction="up" timeout={1500}>
                <div>
                  <Fade in timeout={3000}>
                    <h3>
                      Un team di professionisti efficiente, snello e agile.
                    </h3>
                  </Fade>
                </div>
              </Slide>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.sectionTop}>
          <WebyliaSection />
          <Divider />
          <TeamSection />
        </div>
      </div>
    </div>
  );
}
