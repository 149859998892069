import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Collapse from "@material-ui/core/Collapse";
import CardMK from "../../../components/Card/Card.js";

import CardBody from "../../../components/Card/CardBody";
import Box from "@material-ui/core/Box";

import CardFooter from "../../../components/Card/CardFooter";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import noccioloProblemiImage from "../../../assets/img/noccioloProblemiSmall.jpg";
import ButtonBase from "@material-ui/core/ButtonBase";

import styles from "../metodiStyle";

const useStyles = makeStyles(styles);
export default function NoccioloCard() {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 1800);

  const [expanded, setExpanded] = React.useState(false);
  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const classes = useStyles();

  return (
    <CardMK className={classes[cardAnimaton]}>
      <ButtonBase onClick={handleExpand}>
        <img
          title="Design Thinking Agile Lean, superamento dei preconcetti"
          alt="Design Thinking Agile Lean, superamento dei preconcetti"
          src={noccioloProblemiImage}
          className={classes.cardImage}
        />
      </ButtonBase>
      <Button
        color="primary"
        onClick={handleExpand}
        className={classes.bottonCollaps}
      >
        Superamento dei preconcetti
        <ExpandMoreIcon
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
        />
      </Button>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardBody>
          <Typography
            variant="body1"
            // style={{ marginTop: "30px" }}
            component="div"
            align="justify"
            variant="body1"
            gutterBottom
            className={classes.descrizioneVoce}
          >
            <Box style={{ marginBottom: "1rem" }}>
              Concentrarsi solo su ciò che crea valore per il cliente richiede
              di non perdersi in ciò che è superfluo e di non dare nulla per
              assoluto o definitivo. Sembra una banalità ma spesso rimaniamo
              incastrati in preconcetti ed abitudini senza rendercene conto.
            </Box>
            <Box style={{ marginBottom: "1rem" }}>
              Design Thinking, Agile e Lean ci vengono in aiuto con strumenti
              differenti, ma che hanno in comune il vantaggio di evidenziarci
              gli sprechi, i colli di bottiglia e eventuali aspetti trascurati
              ma importanti. Fare di queste metodologie pratiche una propria
              abitudine quotidiana, ci aiuterà a portare ai nostri clienti la
              massima affidabilità, qualità e soddisfazione.
            </Box>
          </Typography>
        </CardBody>
        <CardFooter className={classes.cardFooter}></CardFooter>
      </Collapse>
    </CardMK>
  );
}
