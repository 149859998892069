import {
  container,
  containerFluid,
  title,
} from "assets/jss/material-kit-react.js";

const spikeStyle = {
  section: {
    background: "radial-gradient(ellipse at center,#585858 0,#232323 100%)",
    padding: "70px 0",
    textAlign: "center",
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    color: "#eee",
  },
  description: { color: "#ddd", fontWeight: 400 },
  container,
  containerFluid,
  textCenter: { textAlign: "center" },
  // description: { color: "#666" },
  card: { width: "100%", marginBottom: 20, marginTop: 20, align: "center" },
  gridTop: { marginBottom: 40, marginTop: 40, align: "center" },

  justifyCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export default spikeStyle;
