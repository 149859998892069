import {
	container,
	title,
	cardTitle,
	successColor,
	warningColor,
	dangerColor,
	infoColor,
	typography,
} from "../../assets/jss/material-kit-react.js";

import imagesStyle from "../../assets/jss/material-kit-react/imagesStyles.js";

const chiSiamoPageStyle = {
	descrizioneVoce: {
		fontSize: "18px",
		fontWeight: "300",
	},
	successColor: {
		color: successColor,
	},
	warningColor: {
		color: warningColor,
	},
	dangerColor: {
		color: dangerColor,
	},
	infoColor: {
		color: infoColor,
	},
	descrizioneVoceLista: {
		fontSize: "16px",
		padding: "10px 0px",
	},
	leggiDiPiuVoceLista: {
		fontSize: "12px",
		padding: "8px 0px",
		textAlign: "right",
	},
	container: {
		maxWidth: "3000px",
		padding: 0,
		zIndex: "12",
		color: "#FFFFFF",
		...container,
	},
	sectionTop: {
		padding: "50px 0px 80px 0px",
		textAlign: "center",
	},
	section: {
		padding: "30px 10px",
		textAlign: "center",
	},
	sectionAlternate: {
		padding: "70px 70px",
		textAlign: "center",
		background: "rgba(0, 0, 0, 0.2)",
	},
	parallaxContainer: {
		zIndex: "12",
		color: "#FFFFFF",
		...container,
	},
	parallaxTitle: {
		...title,
		display: "inline-block",
		position: "relative",
		marginTop: "30px",
		marginBottom: "5px",
		minHeight: "32px",
		color: "#FFFFFF",
		textDecoration: "none",
	},
	parallaxSubTitle: {
		// ...title,
		display: "inline-block",
		position: "relative",
		marginTop: "0px",
		// minHeight: "32px",
		color: "#FFFFFF",
		textDecoration: "none",
	},
	profile: {
		textAlign: "center",
		"& img": {
			maxWidth: "160px",
			width: "100%",
			margin: "0 auto",
			transform: "translate3d(0, -50%, 0)",
		},
	},
	description: { color: "#344334" },
	// description: {
	//   margin: "1.071rem auto 0",
	//   maxWidth: "600px",
	//   color: "#999",
	//   textAlign: "center !important",
	// },
	name: {
		marginTop: "-80px",
	},
	avatarConteiner: {
		marginTop: "50px",
	},
	...imagesStyle,
	main: {
		background: "#FFFFFF",
		position: "relative",
		zIndex: "3",
	},
	mainRaised: {
		margin: "-60px 30px 0px",
		borderRadius: "6px",
		boxShadow:
			"0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
	},
	title: {
		...title,
		// display: "inline-block",
		// position: "relative",
		marginTop: "30px",
		marginBottom: "4px",
		minHeight: "32px",
		textDecoration: "none",
	},
	title2: {
		marginBottom: "3em",
	},
	socials: {
		marginTop: "0",
		width: "100%",
		transform: "none",
		left: "0",
		top: "0",
		height: "100%",
		lineHeight: "41px",
		fontSize: "20px",
		color: "#999",
	},
	margin5: {
		margin: "5px",
	},
	navWrapper: {
		margin: "20px auto 50px auto",
		textAlign: "center",
	},
	accorditionHeading: {
		fontSize: 15,
		backgroundColor: "#eee",
	},
	cardTitle,
	smallTitle: {
		color: "#6c757d",
	},
	justifyCenter: {
		justifyContent: "center !important",
	},
	...imagesStyle,
	itemGrid: {
		marginLeft: "auto",
		marginRight: "auto",
		maxWidth: "100%",
	},
	centerList: {
		// width: "70%",
		textAlign: "center",
		color: "#344334",
		fontSize: "1.125rem",
		lineHeight: "1.5em",

		// clipPath: "polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)",
	},
	leftList: {
		// width: "70%",
		textAlign: "left",
		color: "#344334",
		fontSize: "1.125rem",
		lineHeight: "1.5em",

		// clipPath: "polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)",
	},
	centerSentence: {
		// width: "70%",
		marginTop: 50,
		textAlign: "center",
		color: "#344334",
		fontSize: "1.125rem",
		lineHeight: "1.5em",

		// clipPath: "polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)",
	},
	imageRelative: { height: "auto", maxWidth: "100%" },
	imageProfile: { maxWidth: "120px" },
	imageCertificazione: { maxWidth: "80%" },
};

export default chiSiamoPageStyle;
