import React, { useEffect, useRef } from "react";

import Header from "../../components/Header/Header";
import HeaderLinks from "../../components/Header/HeaderLinks";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "./cookiePolicyStyle.js";
import CookiePolicyImage from "../../assets/img/eu-flag.jpg";

const useStyles = makeStyles(styles);

export default function CookiePolicy(props) {
  const topRef = useRef(null);
  const executeScrollToTop = () => {
    topRef.current.scrollIntoView();
  };
  useEffect(executeScrollToTop);

  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const bull = <span className={classes.bullet}>•</span>;
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

  function createCookieData(nome, tipo, dettaglio, descrizione) {
    return { nome, tipo, dettaglio, descrizione };
  }
  const rowsCookie = [
    createCookieData(
      "Google Analytics",
      "Analytics di terze parti",
      ".ga; .gat; _utmc; _utmv; _utmb; _utma; _utmz",
      "Utilizzati per la raccolta anonima di informazioni sugli utenti collegati"
    ),
    createCookieData(
      "JavaScript",
      "Tecnico di sessione",
      ".has_js",
      "Utilizzato per mantenere attiva l'informazione sulla autorizzazione all'uso di codice JavaScript"
    ),
  ];

  return (
    <div ref={topRef}>
      <Header
        color="transparent"
        brand="Webylia"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "white",
        }}
      />
      <Parallax small filter image={CookiePolicyImage}>
        <div className={classes.parallaxContainer}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <h1 className={classes.parallaxTitle}>Cookie Policy</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <GridContainer justify="center" style={{ padding: "0px 16px" }}>
          <GridItem xs={12} sm={12} md={8}>
            <Typography variant="body1" style={{ marginTop: "30px" }}>
              Questa informativa sull'utilizzo dei cookie è resa all'utente
              secondo quanto previsto dal Provvedimento Garante Privacy n. 229
              dell'8/5/2014 "Individuazione delle modalità semplificate per
              l'informativa e l'acquisizione del consenso per l'uso dei cookie"
              e nel rispetto del Regolamento 2016/679/UE – GDPR relativo alla
              protezione delle persone fisiche con riguardo al trattamento dei
              dati personali.
            </Typography>
            <Typography className={classes.paragraphMargin} component="div">
              Questo sito si avvale di:
              <List className={classes.listMargin}>
                <ListItem className={classes.itemMargin}>
                  - cookie di tipo tecnico, necessari al corretto funzionamento
                  della navigazione;
                </ListItem>
                <ListItem className={classes.itemMargin}>
                  - cookie di tipo analitico, utilizzati esclusivamente per
                  informazioni aggregate ed anonime con finalità statistiche.
                </ListItem>
              </List>
            </Typography>
            <Typography className={classes.paragraphMargin}>
              Il presente sito non utilizza cookie di profilazione o di
              condivisione social.
            </Typography>
            <h5 className={classes.sectionMargin}>
              <Box fontWeight="Bold">1 - Che cosa sono i cookie</Box>
            </h5>
            <Typography>
              Quando si accede a un sito web da un qualsiasi dispositivo, sono
              generalmente inviati e memorizzati sul dispositivo stesso dei
              piccoli file di testo, denominati “cookie”.
              <br />I cookie sono molto comuni e sono utilizzati da numerosi
              siti web, poiché permettono al gestore del sito web di ricavare
              informazioni utili, come, per esempio, di sapere se un computer (o
              anche il relativo utente) ha visitato il sito web in precedenza, e
              quali pagine ha visitato.
              <br />
              In genere, la finalità dei cookie è migliorare il funzionamento
              del sito web e l'operatività  dell'utente nell'utilizzo dello
              stesso.
              <br />I cookie possono anche essere utilizzati per inviare
              messaggi pubblicitari, il che ha spinto il Garante della Privacy
              ad emanare il Provvedimento sopra citato.
              <br />
              Per maggiori informazioni su che cosa sono i cookie e come
              funzionano, è possibile consultare, per esempio, il sito di
              Wikipedia.
            </Typography>
            <h5 className={classes.sectionMargin}>
              <Box fontWeight="Bold">2 - Tipologie di cookie</Box>
            </h5>
            <Typography>
              I cookie si possono classificare in base al loro tipo di utilizzo:
            </Typography>
            <List className={classes.listMargin}>
              <ListItem
                component={Typography}
                variant="body2"
                className={classes.itemMargin}
              >
                - Cookie TECNICI (di navigazione, di sessione, di funzionalità,
                analytics);
              </ListItem>
              <ListItem
                component={Typography}
                variant="body2"
                className={classes.itemMargin}
              >
                - Cookie di PROFILAZIONE;
              </ListItem>
              <ListItem
                component={Typography}
                variant="body2"
                className={classes.itemMargin}
              >
                - Cookie di TERZE PARTI;
              </ListItem>
              <ListItem
                component={Typography}
                variant="body2"
                className={classes.itemMargin}
              >
                - Cookie di CONDIVISIONE SOCIAL.
              </ListItem>
            </List>
            <h6 className={classes.sectionMargin}>
              <Box fontWeight="Bold">
                2.1 - Cookie TECNICI di navigazione o di sessione
              </Box>
            </h6>
            <Typography variant="body2">
              Sono utilizzati al fine di effettuare la trasmissione di una
              comunicazione per consentire la corretta fruizione del sito web.
              <br />
              Tali cookie possono essere distinti in ragione della loro
              persistenza sul terminale dell’utente: quelli che si cancellano
              automaticamente alla fine di ogni navigazione, si chiamano
              “session cookie”. Se viceversa essi hanno una vita più lunga, si
              parla di cookie permanenti.
              <br />
              Per l’installazione di tali cookie non è richiesto il preventivo
              consenso dell’utente.
            </Typography>
            <h6 className={classes.sectionMargin}>
              <Box fontWeight="Bold">2.2 - Cookie TECNICI di funzionalità</Box>
            </h6>
            <Typography variant="body2">
              Sono utilizzati per fornire servizi o per ricordare le
              impostazioni per migliorare la visita e l’esperienza dell’utente
              nel  sito. Permettono all’utente la navigazione in funzione di una
              serie di criteri selezionati (ad esempio, la lingua).
              <br />
              Per l’installazione di tali cookie non è richiesto il preventivo
              consenso dell’utente.
            </Typography>
            <h6 className={classes.sectionMargin}>
              <Box fontWeight="Bold">2.3 - Cookie TECNICI analytics</Box>
            </h6>
            <Typography variant="body2">
              Sono assimilati ai cookie tecnici laddove utilizzati direttamente
              dal gestore del sito per raccogliere informazioni, in forma
              aggregata e anonima, sul numero degli utenti e su come questi
              visitano il sito stesso.
              <br />
              Tali cookie raccolgono informazioni su come è utilizzato un sito
              internet e permettono al proprietario di un sito web di avere una
              migliore conoscenza dei propri utenti e di migliorare il
              funzionamento del sito stesso (ad esempio: le pagine visitate, gli
              errori che si possono incontrare, il calcolo degli utenti che
              stanno usando i servizi in modo da assicurare la capacità
              necessaria a una navigazione veloce).
              <br />
              In alcuni casi, alcuni di questi cookie sono gestiti da terzi (a
              titolo di esempio, Google Analytics), ma non è consentito loro di
              utilizzare i cookie per scopi diversi da quelli sopra elencati.
              <br />
              Per l’installazione di tali cookie non è richiesto il preventivo
              consenso dell’utente.
            </Typography>
            <h6 className={classes.sectionMargin}>
              <Box fontWeight="Bold">2.4 - Cookie di PROFILAZIONE</Box>
            </h6>
            <Typography variant="body2">
              I cookie di profilazione sono volti a creare profili relativi
              all’utente e vengono utilizzati al fine di inviare messaggi
              pubblicitari in linea con le preferenze manifestate dallo stesso
              nell’ambito della navigazione in rete.
              <br />
              In ragione della particolare invasività che tali dispositivi
              possono avere nell’ambito della sfera privata degli utenti, la
              normativa europea e italiana prevede che l’utente debba essere
              adeguatamente informato sull’uso degli stessi ed esprimere così il
              proprio valido consenso, oltre a permettere in qualsiasi momento
              la loro disinstallazione.
              <br />
              Il presente sito non utilizza cookie di questo tipologia.
            </Typography>
            <h6 className={classes.sectionMargin}>
              <Box fontWeight="Bold">2.5 - Cookie di TERZE PARTI</Box>
            </h6>
            <Typography variant="body2">
              Si definiscono cookie di “terze parti” quelli forniti da soggetti
              terzi rispetto al proprietario del sito, che forniscono questi
              servizi in cambio di informazioni in merito alla visita
              dell’utente.
              <br />
              Ne discende anche per i terzi fornitori di cookie l’obbligo di
              rispettare la normativa in materia. Per tale motivo si rimanda ai
              link delle pagine web del sito della terza parte, nelle quali
              l’utente potrà trovare i moduli di raccolta del consenso ai cookie
              e le loro relative informative.
              <br />I servizi di terze parti utilizzati dal presente sito sono
              quelli forniti da Google Analytics in forma anonimizzata, la cui
              normativa completa può essere trovata a questo link:
              {/* <br /> */}
              <Box fontWeight="Bold" component="span">
                <a
                  href="http://www.google.it/intl/it/policies/privacy/"
                  target="_blank"
                >
                  &nbsp; http://www.google.it/intl/it/policies/privacy/
                </a>
              </Box>
            </Typography>
            <h6 className={classes.sectionMargin}>
              <Box fontWeight="Bold">2.6 - Cookie di CONDIVISIONE SOCIAL</Box>
            </h6>
            <Typography variant="body2">
              Questi cookie facilitano la condivisione dei contenuti del sito
              attraverso social network quali Facebook, Twitter, etc.
              <br />
              Qualora siano presenti link a detti siti, per prendere visione
              delle rispettive privacy e cookie policy è possibile visitare i
              siti web dei social network stessi.
              <br />
              Il presente sito non utilizza cookie di questo tipologia.
            </Typography>
            <h5 className={classes.sectionMargin}>
              <Box fontWeight="Bold">
                3 - Come modificare le impostazioni sui cookie
              </Box>
            </h5>
            <Typography>
              La maggior parte dei browser accetta automaticamente i cookie, ma
              l’utente normalmente può modificare le impostazioni per
              disabilitare tale funzione.
              <br />
              E' possibile bloccare tutte le tipologie di cookie, oppure
              accettare di riceverne soltanto alcuni e disabilitarne altri. La
              sezione "Opzioni" o "Preferenze" nel menu del browser permette di
              evitare di ricevere cookie e altre tecnologie di tracciamento
              utente, e come ottenere notifica dal browser dell’attivazione di
              queste tecnologie.
              <br />
              In alternativa, è anche possibile consultare la sezione “Aiuto”
              della barra degli strumenti presente nella maggior parte dei
              browser.
              <br />
              E' importante ricordare che la completa disattivazione di tutti i
              cookie, compresi quelli tecnici, può comportare un anomalo
              funzionamento del sito che si sta visitando.
            </Typography>
            <h5 className={classes.sectionMargin}>
              <Box fontWeight="Bold">4 - Cookie utilizzati da questo sito</Box>
            </h5>
            <Typography>
              Di seguito vengono elencati i cookie utilizzati dal presente sito:
            </Typography>
            <TableContainer
              component={Paper}
              elevation={3}
              style={{ marginTop: "20px", marginBottom: "40px" }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCell} align="center">
                      Denominazione
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      Tipo
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      Dettaglio
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      Descrizione
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsCookie.map((row) => (
                    <TableRow key={row.nome}>
                      <TableCell
                        className={classes.tableCell}
                        align="center"
                        component="th"
                        scope="row"
                      >
                        {row.nome}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {row.tipo}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {row.dettaglio}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {row.descrizione}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
