import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import ContattiShortComp from "../../Contatti/ContattiShortComp";

// @material-ui/icons
import AvTimer from "@material-ui/icons/AvTimer";
import EuroSymbol from "@material-ui/icons/EuroSymbol";
import EmojiEvents from "@material-ui/icons/EmojiEvents";
import People from "@material-ui/icons/People";
import ListAlt from "@material-ui/icons/ListAlt";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Link from "@material-ui/core/Link";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";

import styles from "../pacchettiStyle.js";
import VSMScrumImage from "../../../assets/img/VSMScrum.png";

const useStyles = makeStyles(styles);

export default function VSMScrumSection() {
	const [expanded, setExpanded] = React.useState(false);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	const classes = useStyles();
	const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
	return (
		<div className={classes.section} id="VSMScrumSection">
			<GridContainer justify="center">
				<GridItem xs={12} sm={12} md={10}>
					<h2 className={classes.title}>Value Stream Mapping + Scrum</h2>
					<GridContainer justify="center">
						<GridItem xs={12} sm={12} md={4}>
							<img
								alt="Value Stream Mapping + Scrum"
								title="Value Stream Mapping + Scrum"
								src={VSMScrumImage}
								className={navImageClasses}
							/>
						</GridItem>
						<GridItem xs={12} sm={12} md={4}>
							<List>
								<ListItem alignItems="flex-start">
									<ListItemAvatar>
										<AvTimer color="error" fontSize="large" />
									</ListItemAvatar>
									<ListItemText
										disableTypography
										secondary={
											<React.Fragment>
												<Typography
													component="div"
													variant="h6"
													className={classes.inline}
													color="textPrimary"
												>
													Durata:
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={classes.descrizioneVoce}
													color="textPrimary"
												>
													{"6 settimane. Il pacchetto prevede 15 giornate per formazione, " +
														"consulenza, coaching e Scrum Master. Durante il progetto, " +
														"lo Scrum Team aziendale svolgerà in autonomia alcune attività " +
														"con un impegno dipendente dalla complessità del processo da mappare."}
												</Typography>
											</React.Fragment>
										}
									/>
								</ListItem>
								<Divider variant="inset" component="li" />
								{/* <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <EuroSymbol
                      className={classes.successColor}
                      fontSize="large"
                    />
                  </ListItemAvatar>
                  <ListItemText
                    disableTypography
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="div"
                          variant="h6"
                          className={classes.inline}
                          color="textPrimary"
                        >
                          {"Costo: "}
                        </Typography>
                        <Typography
                          component="div"
                          variant="body1"
                          className={classes.descrizioneVoce}
                          color="textPrimary"
                        >
                          {"€9.750 + iva per processo"}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" /> */}
								<ListItem alignItems="flex-start">
									<ListItemAvatar>
										<EmojiEvents
											className={classes.warningColor}
											fontSize="large"
										/>
									</ListItemAvatar>
									<ListItemText
										disableTypography
										secondary={
											<React.Fragment>
												<Typography
													component="div"
													variant="h6"
													// className={classes.inline}
													color="textPrimary"
												>
													{"Obiettivi: "}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={classes.descrizioneVoceLista}
													color="textPrimary"
												>
													{
														"Descrivere ed analizzare il flusso di valore in un processo aziendale prestabilito implementando le tecniche di Value Stream Mapping (VSM) attraverso la metodologia Agile Scrum."
													}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={classes.descrizioneVoceLista}
													color="textPrimary"
												>
													{
														"All’interno del processo mappato identificare le attività che non producono valore per il cliente."
													}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={classes.descrizioneVoceLista}
													color="textPrimary"
												>
													{
														"Individuare strategie di miglioramento del processo e di eliminazione delle attività che non aggiungono valore."
													}
												</Typography>
											</React.Fragment>
										}
									/>
								</ListItem>

								<Divider variant="inset" component="li" />
							</List>
						</GridItem>
						<GridItem xs={12} sm={12} md={4}>
							<List>
								<ListItem alignItems="flex-start">
									<ListItemAvatar>
										<People fontSize="large" />
									</ListItemAvatar>
									<ListItemText
										disableTypography
										secondary={
											<React.Fragment>
												<Typography
													component="div"
													variant="h6"
													// className={classes.inline}
													color="textPrimary"
												>
													{"Destinatari: "}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={classes.descrizioneVoceLista}
													color="textPrimary"
												>
													{
														"Chiunque in azienda sia interessato a migliorare la capacità dei processi aziendali di produrre valore per il cliente."
													}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={classes.descrizioneVoceLista}
													color="textPrimary"
												>
													{
														"Gli imprenditori e i responsabili delle funzioni operative che vogliano gestire i progetti di miglioramento aziendale in modo efficace, efficiente e con basso rischio di fallimento."
													}
												</Typography>
											</React.Fragment>
										}
									/>
								</ListItem>
								<Divider variant="inset" component="li" />
								<ListItem alignItems="flex-start">
									<ListItemAvatar>
										<ListAlt className={classes.infoColor} fontSize="large" />
									</ListItemAvatar>
									<ListItemText
										disableTypography
										secondary={
											<React.Fragment>
												<Typography
													component="div"
													variant="h6"
													// className={classes.inline}
													color="textPrimary"
												>
													{"Programma: "}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={classes.descrizioneVoceLista}
													color="textPrimary"
												>
													{
														"Identificazione del processo da mappare e costituzione dello Scrum Team aziendale incaricato della mappatura."
													}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={classes.descrizioneVoceLista}
													color="textPrimary"
												>
													{"Formazione dello Scrum Team sui principi della "}
													<Link href="/metodi/lean" color="secondary">
														filosofia Lean
													</Link>
													{" e sulla metodologia VSM."}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={classes.descrizioneVoceLista}
													color="textPrimary"
												>
													{"Formazione dello Scrum Team sui principi della "}
													<Link href="/metodi/agile" color="secondary">
														filosofia Agile
													</Link>
													{" e sulla metodologia Scrum."}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={classes.descrizioneVoceLista}
													color="textPrimary"
												>
													{"Visita del processo."}
												</Typography>
												{!expanded && (
													<IconButton
														className={classes.leggiDiPiuVoceLista}
														onClick={handleExpandClick}
														aria-label="show more"
													>
														...leggi di più
													</IconButton>
												)}

												<Collapse in={expanded} timeout="auto" unmountOnExit>
													<Typography
														component="div"
														variant="body1"
														className={classes.descrizioneVoceLista}
														color="textPrimary"
													>
														{
															"Definizione dei dati necessari per la mappatura del processo."
														}
													</Typography>
													<Typography
														component="div"
														variant="body1"
														className={classes.descrizioneVoceLista}
														color="textPrimary"
													>
														{
															"Raccolta dei dati. (Attività a carico dello Scrum Team aziendale)"
														}
													</Typography>
													<Typography
														component="div"
														variant="body1"
														className={classes.descrizioneVoceLista}
														color="textPrimary"
													>
														{
															"Analisi dei dati e redazione della mappa preliminare."
														}
													</Typography>
													<Typography
														component="div"
														variant="body1"
														className={classes.descrizioneVoceLista}
														color="textPrimary"
													>
														{
															"Verifica sul campo dei dati raccolti. (Attività a carico dello Scrum Team aziendale)"
														}
													</Typography>
													<Typography
														component="div"
														variant="body1"
														className={classes.descrizioneVoceLista}
														color="textPrimary"
													>
														{
															"Redazione, lettura ed interpretazione della mappa definitiva e confronto di gruppo sulle possibili strategie di miglioramento del processo."
														}
													</Typography>
													<Typography
														component="div"
														variant="body1"
														className={classes.descrizioneVoceLista}
														color="textPrimary"
													>
														{
															"Presentazione e discussione dei risultati del progetto."
														}
													</Typography>
													<Typography
														component="div"
														variant="body1"
														className={classes.descrizioneVoceLista}
														color="textPrimary"
													>
														<IconButton
															className={classes.leggiDiPiuVoceLista}
															onClick={handleExpandClick}
															aria-expanded={expanded}
															aria-label="show more"
														>
															...leggi di meno
														</IconButton>
													</Typography>
												</Collapse>
											</React.Fragment>
										}
									/>
								</ListItem>
								<Divider variant="inset" component="li" />
								<ContattiShortComp classes={classes} align="right" />
							</List>
						</GridItem>
					</GridContainer>
				</GridItem>
			</GridContainer>
		</div>
	);
}
