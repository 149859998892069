import {
  container,
  title,
  successColor,
  warningColor,
  infoColor,
} from "assets/jss/material-kit-react.js";

import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";

const cookiePolicyStyle = {
  paragraphMargin: {
    marginTop: "10px",
  },
  listMargin: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  itemMargin: {
    // lineHeight: "0.5",
    paddingBottom: 0,
    paddingTop: 0,
  },
  sectionMargin: {
    marginTop: "20px",
  },
  successColor: {
    color: successColor,
  },
  warningColor: {
    color: warningColor,
  },
  infoColor: {
    color: infoColor,
  },
  descrizioneVoceLista: {
    fontSize: "16px",
    padding: "10px 0px",
  },
  leggiDiPiuVoceLista: {
    fontSize: "12px",
    padding: "8px 0px",
    textAlign: "right",
  },
  container: {
    maxWidth: "3000px",
    padding: 0,
    zIndex: "12",
    color: "#FFFFFF",
    ...container,
  },
  section: {
    padding: "70px 70px",
    textAlign: "center",
  },
  sectionAlternate: {
    padding: "70px 70px",
    textAlign: "center",
    background: "rgba(0, 0, 0, 0.2)",
  },
  parallaxContainer: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container,
  },
  parallaxTitle: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none",
  },
  profile: {
    textAlign: "center",
    "& img": {
      maxWidth: "160px",
      width: "100%",
      margin: "0 auto",
      transform: "translate3d(0, -50%, 0)",
    },
  },
  description: {
    margin: "1.071rem auto 0",
    maxWidth: "600px",
    color: "#999",
    textAlign: "center !important",
  },
  name: {
    marginTop: "-80px",
  },
  ...imagesStyle,
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
  title: {
    ...title,
    // display: "inline-block",
    // position: "relative",
    marginTop: "30px",
    marginBottom: "3rem",
    minHeight: "32px",
    textDecoration: "none",
  },
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#999",
  },
  navWrapper: {
    margin: "20px auto 50px auto",
    textAlign: "center",
  },
  bullet: {
    // display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  tableCell: {
    paddingRight: "4px",
    paddingLeft: "5px",
  },
};

export default cookiePolicyStyle;
