import React, { useEffect, useRef } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Fade from "@material-ui/core/Fade";
// core components

import Header from "../../components/Header/Header";
import HeaderLinks from "../../components/Header/HeaderLinks";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "../../components/Parallax/Parallax.js";
import DesignThinkingSection from "./Sections/DesignThinkingSection";
import Lego4AgileSection from "./Sections/Lego4AgileSection";
import VSMScrumSection from "./Sections/VSMScrumSection";
import LeanThinkingSection from "./Sections/LeanThinkingSection";
import KaizenOfficeSection from "./Sections/KaizenOfficeSection";
import TeatroPerDTSection from "./Sections/TeatroPerDTSection";

import styles from "./pacchettiStyle.js";
import heroPacchettiImages from "../../assets/img/hero-pacchetti3Medium.jpg";

const useStyles = makeStyles(styles);

export default function Pacchetti(props) {
	const topRef = useRef(null);
	const DesignThinkingSectionRef = useRef(null);
	const Lego4AgileSectionRef = useRef(null);
	const LeanThinkingSectionRef = useRef(null);
	const KaizenOfficeSectionRef = useRef(null);
	const VSMScrumSectionRef = useRef(null);
	const TeatroPerDTSectionRef = useRef(null);

	const executeScrollToTop = () => {
		topRef.current.scrollIntoView();
		if (props.location.state) {
			switch (props.location.hash) {
				case "#Lego4AgileSection":
					Lego4AgileSectionRef.current.scrollIntoView({ behavior: "smooth" });
					break;

				case "#DesignThinkingSection":
					DesignThinkingSectionRef.current.scrollIntoView({
						behavior: "smooth",
					});
					break;

				case "#LeanThinkingSection":
					LeanThinkingSectionRef.current.scrollIntoView({
						behavior: "smooth",
					});
					break;

				case "#KaizenOfficeSection":
					KaizenOfficeSectionRef.current.scrollIntoView({
						behavior: "smooth",
					});
					break;

				case "#VSMScrumSection":
					VSMScrumSectionRef.current.scrollIntoView({
						behavior: "smooth",
					});
					break;

				case "#TeatroPerDTSection":
					TeatroPerDTSectionRef.current.scrollIntoView({
						behavior: "smooth",
					});
					break;
				default:
					//Default will perform if all case’s fail

					break;
			}
		}
	};
	useEffect(executeScrollToTop);

	const classes = useStyles();
	const { ...rest } = props;

	// const imageClasses = classNames(
	//   classes.imgRaised,
	//   classes.imgRoundedCircle,
	//   classes.imgFluid
	// );
	// const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
	return (
		<div ref={topRef}>
			<Header
				color="transparent"
				// routes={dashboardRoutes}
				brand="Webylia"
				rightLinks={<HeaderLinks />}
				fixed
				changeColorOnScroll={{
					height: 100,
					color: "white",
				}}
				// {...rest}
			/>
			<Parallax medium filter image={heroPacchettiImages}>
				<div className={classes.parallaxContainer}>
					<GridContainer>
						<GridItem xs={12} sm={12} md={12}>
							<Fade in timeout={3000}>
								<h1 className={classes.parallaxTitle}>
									Il primo passo per l'innovazione.
								</h1>
							</Fade>
							<Slide in direction="up" timeout={1500}>
								<div>
									<Fade in timeout={3000}>
										<h3>
											Interventi di formazione, coaching e consulenza per
											acquisire strumenti di innovazione
										</h3>
									</Fade>
								</div>
							</Slide>
						</GridItem>
					</GridContainer>
				</div>
			</Parallax>
			<div className={classNames(classes.main, classes.mainRaised)}>
				<div id="DesignThinkingSection" ref={DesignThinkingSectionRef}>
					<DesignThinkingSection />
				</div>
				<div ref={Lego4AgileSectionRef} id="Lego4AgileSection">
					<Lego4AgileSection />
				</div>
				<div ref={LeanThinkingSectionRef} id="LeanThinkingSection">
					<LeanThinkingSection />
				</div>
				<div ref={KaizenOfficeSectionRef} id="KaizenOfficeSection">
					<KaizenOfficeSection />
				</div>
				<div ref={VSMScrumSectionRef} id="VSMScrumSection">
					<VSMScrumSection />
				</div>
				<div ref={TeatroPerDTSectionRef} id="TeatroPerDTSection">
					<TeatroPerDTSection />
				</div>
			</div>
		</div>
	);
}
