import { container, title } from "assets/jss/material-kit-react.js";

import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";

const contattiStyle = {
  container,

  section: {
    padding: "10px 10px",
  },
  rudjparallaxContainer: {
    zIndex: "12",
    color: "#FFFFFF",
    marginTop: "-14vh",
    marginBottom: "2vmin",
    ...container,
  },
  parallaxTitle: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    marginBottom: "5px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none",
  },
  profile: {
    textAlign: "center",
    "& img": {
      maxWidth: "160px",
      width: "100%",
      margin: "0 auto",
      transform: "translate3d(0, -50%, 0)",
    },
  },
  description: {
    margin: "1.071rem auto 0",
    maxWidth: "600px",
    color: "#999",
    textAlign: "center !important",
  },
  name: {
    marginTop: "-80px",
  },
  ...imagesStyle,
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
  },
  mainRaised: {
    // margin: "-550px 0px 0px 0px ",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
  title: {
    ...title,
    display: "flex",
    textAlign: "center",
    position: "relative",
    // marginTop: "30px",
    minHeight: "none",
    textDecoration: "none",
  },
  titleH3: {
    ...title,
    // fontSize: "2.5rem",
    // display: "flex",
    textAlign: "center",
    position: "relative",
    // marginTop: "30px",
    minHeight: "none",
    textDecoration: "none",
    margin: "1.2rem 0 0.875rem",
  },
  titleH5: {
    ...title,
    fontSize: "1.2rem",
    // display: "flex",
    // textAlign: "center",
    position: "relative",
    // marginTop: "30px",
    minHeight: "none",
    textDecoration: "none",
    margin: "1.2rem 0 0.875rem",
  },
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#999",
  },
  navWrapper: {
    margin: "50px 0 10px 0",
    textAlign: "left",
  },
  venicePin: {
    position: "relative",
    marginTop: "0",
    width: "100%",
    // transform: "none",
    transform: "translate(66%,-22%)",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#FF0F66",
  },
  coneglianoPin: {
    position: "absolute",
    marginTop: "0",
    width: "100%",
    // transform: "none",
    transform: "translate(68%,-48%)",
    left: "0",
    top: "-100",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#FF0F66",
  },
  auronzoPin: {
    position: "absolute",
    marginTop: "0",
    width: "100%",
    // transform: "none",
    transform: "translate(77%,-89%)",
    left: "0",
    top: "-100",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#FF0F66",
  },
  paddingImage: {
    align: "right",
    marginTop: 30,
    marginBottom: 30,
    height: "auto",
    maxWidth: "80%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  noPaddingLeft: { paddingLeft: 0 },
  iconLink: { margin: "0rem 0.3rem", color: "#89229b" },
  iconColorAlternate: { color: "#45ff45" },
};

export default contattiStyle;
