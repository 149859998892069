/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// @material-ui/icons
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import ExploreIcon from "@material-ui/icons/Explore";
import GroupIcon from "@material-ui/icons/Group";
import PanToolIcon from "@material-ui/icons/PanTool";
import TouchAppIcon from "@material-ui/icons/TouchApp";

import styles from "../../assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Link to="/pacchetti" color="transparent" className={classes.navLink}>
          <DynamicFeedIcon className={classes.icons} /> Pacchetti
        </Link>
      </ListItem>

      <ListItem className={classes.listItem}>
        <Link to="/servizi" color="transparent" className={classes.navLink}>
          <ExploreIcon className={classes.icons} /> Servizi
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/chisiamo" color="transparent" className={classes.navLink}>
          <GroupIcon className={classes.icons} /> Chi Siamo
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/contatti" color="transparent" className={classes.navLink}>
          <TouchAppIcon className={classes.icons} /> Contatti
        </Link>
      </ListItem>
    </List>
  );
}
