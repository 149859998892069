import {
  container,
  title,
  successColor,
  warningColor,
  infoColor,
} from "../../assets/jss/material-kit-react.js";

import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";
import { orange } from "@material-ui/core/colors";

const pacchettiStyle = {
  descrizioneVoce: {
    fontSize: "16px",
  },
  successColor: {
    color: successColor,
  },
  warningColor: {
    color: warningColor,
  },
  infoColor: {
    color: infoColor,
  },
  descrizioneVoceLista: {
    fontSize: "16px",
    padding: "10px 0px",
  },
  descrizioneVoceListaIndentata: {
    fontSize: "16px",
    padding: "0px 0px 0px 15px",
  },
  leggiDiPiuVoceLista: {
    fontSize: "12px",
    padding: "8px 0px",
    textAlign: "right",
  },
  container: {
    maxWidth: "3000px",
    padding: 0,
    zIndex: "12",
    color: "#FFFFFF",
    ...container,
  },
  section: {
    padding: "70px 0px",
    textAlign: "center",
  },
  sectionAlternate: {
    padding: "70px 0px 70px 0px",
    textAlign: "center",
    background: "radial-gradient(ellipse at center,#585858 0,#232323 100%)",
  },
  parallaxContainer: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container,
  },
  parallaxTitle: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none",
  },
  profile: {
    textAlign: "center",
    "& img": {
      maxWidth: "160px",
      width: "100%",
      margin: "0 auto",
      transform: "translate3d(0, -50%, 0)",
    },
  },
  description: {
    margin: "1.071rem auto 0",
    maxWidth: "600px",
    color: "#999",
    textAlign: "center !important",
  },
  name: {
    marginTop: "-80px",
  },
  ...imagesStyle,
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
  title: {
    ...title,
    // display: "inline-block",
    // position: "relative",
    marginTop: "30px",
    marginBottom: "4px",
    minHeight: "32px",
    textDecoration: "none",
  },
  textColorAlternate: { color: "#fff" },
  dividerColorAlternate: {
    "& .MuiDivider-root & .MuiDivider-inset": {
      backgroundColor: "#fff!important",
      // backgroundColor: "#linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    },
  },

  titleAlternate: {
    ...title,
    color: "#fff",
    // display: "inline-block",
    // position: "relative",
    marginTop: "30px",
    marginBottom: "4px",
    minHeight: "32px",
    textDecoration: "none",
  },
  title2: {
    marginBottom: "3em",
  },
  title2Alternate: {
    marginBottom: "3em",
    color: "#acb5bd",
  },
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#999",
  },
  navWrapper: {
    margin: "20px auto 50px auto",
    textAlign: "center",
  },
  hrMargin: {
    marginLeft: "4.5rem",
  },
};

export default pacchettiStyle;
