import React, { useEffect, useState, useRef, useLayoutEffect } from "react";

import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Chat from "@material-ui/icons/Chat";
import Build from "@material-ui/icons/Build";
// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";

import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import styles from "./spikeStyle";
import Fade from "@material-ui/core/Fade";

import DesignThinkingImage from "../../../assets/img/DTSpike.png";
import Lego4AgileImage from "../../../assets/img/scrum-simulation-with-lego-agile-game-4-638.jpg";
// import LeanThinkingImage from "../../../assets/img/LeanThinkingImageScaled.jpeg";
import LeanThinkingImage from "../../../assets/img/LeanThinkingImage2.jpg";
import AgileKanbanImage from "../../../assets/img/AgileKanban.jpg";
import VSMScrumImage from "../../../assets/img/VSMScrum.png";
import TeatroPerDTImage from "../../../assets/img/TeatroPerDT.jpg";

const useStyles = makeStyles(styles);

export default function SpikeSection() {
	const spikesGrid = useRef(null);
	const [show, doShow] = useState({
		itemOne: false,
		// itemTwo: false,
		// itemThree: false,
	});
	useLayoutEffect(() => {
		const spikesGridPosition = spikesGrid.current.getBoundingClientRect().top;
		const onScroll = () => {
			const scrollPosition = window.scrollY + window.innerHeight;
			if (spikesGridPosition < scrollPosition) {
				doShow((state) => ({ ...state, itemOne: true }));
			} else {
				doShow((state) => ({ ...state, itemOne: false }));
			}
		};

		window.addEventListener("scroll", onScroll);
		return () => window.removeEventListener("scroll", onScroll);
		/* 
       remove the event listener in the cleanup function 
       to prevent memory leaks
    */
	}, []);

	const classes = useStyles();
	return (
		<div className={classes.section} id="SpikeSection">
			<div className={classes.container}>
				{/* <div> */}
				<h2 className={classes.title}>Innovazione un passo alla volta</h2>
				<h4 className={classes.description}>
					Siamo convinti che un percorso di innovazione debba essere strutturato
					in attività chiare e circoscritte.
				</h4>
				<h4 className={classes.description}>
					Come primo passo proponiamo pacchetti di formazione, coaching e
					consulenza con durate e obiettivi definiti. Tutti i pacchetti
					presentati possono essere personalizzati.
				</h4>
			</div>
			<div className={classes.containerFluid} ref={spikesGrid} id="spikesGrid">
				<Fade
					in={show.itemOne}
					timeout={3000}
					style={{ transitionDelay: "200ms" }}
				>
					<GridContainer justify="center" className={classes.gridTop}>
						<GridItem className={classes.justifyCenter} xs={12} sm={6} md={2}>
							<Card className={classes.card}>
								<CardActionArea
									component={Link}
									to={{
										pathname: "/pacchetti",
										hash: "#DesignThinkingSection",
										state: { fromDashboard: true },
									}}
								>
									<CardMedia
										component="img"
										alt="Design Thinking"
										height="140"
										image={DesignThinkingImage}
										title="Design Thinking"
									/>
									<CardContent>
										<Typography gutterBottom variant="h5" component="h2">
											Design Thinking Spike
										</Typography>
										<Typography
											variant="body2"
											color="textSecondary"
											component="div"
										>
											Come creare valore per il Cliente{" "}
											<Box fontWeight="Bold" display="inline">
												affinando l'ascolto delle sue esigenze con un approccio
												creativo.
											</Box>
										</Typography>
									</CardContent>
								</CardActionArea>
								{/* <CardActions>
                <Button size="small" color="primary">
                    Durata e costo
                  </Button>
                <Button size="small" color="primary">
                  About Design Thinking
                </Button>
              </CardActions> */}
							</Card>
						</GridItem>
						<GridItem className={classes.justifyCenter} xs={12} sm={6} md={2}>
							<Card className={classes.card}>
								<CardActionArea
									component={Link}
									to={{
										pathname: "/pacchetti",
										hash: "#Lego4AgileSection",
										state: {
											fromDashboard: true,
											targetRef: "Lego4AgileSectionRef",
										},
									}}
								>
									<CardMedia
										component="img"
										alt="Lego for Agile"
										height="140"
										image={Lego4AgileImage}
										title="Lego for Agile"
									/>
									<CardContent>
										<Typography gutterBottom variant="h5" component="h2">
											Lego4Agile
										</Typography>
										<Typography
											variant="body2"
											color="textSecondary"
											component="div"
										>
											Come creare valore per il Cliente{" "}
											<Box fontWeight="Bold" display="inline">
												affrontando il cambiamento con flessibilità.
											</Box>
										</Typography>
									</CardContent>
								</CardActionArea>
								{/* <CardActions>
                <Button size="small" color="primary">
                  Durata e costo
                </Button>
                <Button size="small" color="primary">
                  cos'è Agile
                </Button>
              </CardActions> */}
							</Card>
						</GridItem>
						<GridItem className={classes.justifyCenter} xs={12} sm={6} md={2}>
							<Card className={classes.card}>
								<CardActionArea
									component={Link}
									to={{
										pathname: "/pacchetti",
										hash: "#LeanThinkingSection",
										state: { fromDashboard: true },
									}}
								>
									<CardMedia
										component="img"
										alt="Lean Thinking"
										height="140"
										image={LeanThinkingImage}
										title="Lean Thinking"
									/>
									<CardContent>
										<Typography gutterBottom variant="h5" component="h2">
											Lean Thinking
										</Typography>
										<Typography
											variant="body2"
											color="textSecondary"
											component="div"
										>
											Come creare valore per il Cliente{" "}
											<Box fontWeight="Bold" display="inline">
												migliorando i processi e la partecipazione in azienda.
											</Box>
										</Typography>
									</CardContent>
								</CardActionArea>
								{/* <CardActions>
                <Button size="small" color="primary">
                  durata e costo
                </Button>
                <Button size="small" color="primary">
                  about lean
                </Button>
              </CardActions> */}
							</Card>
						</GridItem>
					</GridContainer>
				</Fade>
				<Fade
					in={show.itemOne}
					timeout={3000}
					style={{ transitionDelay: "200ms" }}
				>
					<GridContainer justify="center" className={classes.gridTop}>
						<GridItem className={classes.justifyCenter} xs={12} sm={6} md={2}>
							<Card className={classes.card}>
								<CardActionArea
									component={Link}
									to={{
										pathname: "/pacchetti",
										hash: "#KaizenOfficeSection",
										state: { fromDashboard: true },
									}}
								>
									<CardMedia
										component="img"
										alt="Kaizen Office Kanban"
										height="140"
										image={AgileKanbanImage}
										title="Kaizen Office Kanban"
									/>
									<CardContent>
										<Typography gutterBottom variant="h5" component="h2">
											Kaizen Office
										</Typography>
										<Typography
											variant="body2"
											color="textSecondary"
											component="div"
										>
											<Box fontWeight="Bold" display="inline">
												Disegnare ed avviare un processo{" "}
											</Box>{" "}
											per l’erogazione di servizi con metodologia Agile Kanban.
										</Typography>
									</CardContent>
								</CardActionArea>
								{/* <CardActions>
                <Button size="small" color="primary">
                  Share
                </Button>
                <Button size="small" color="primary">
                  Durata e costi
                </Button>
              </CardActions> */}
							</Card>
						</GridItem>
						<GridItem className={classes.justifyCenter} xs={12} sm={6} md={2}>
							{" "}
							<Card className={classes.card}>
								<CardActionArea
									component={Link}
									to={{
										pathname: "/pacchetti",
										hash: "#VSMScrumSection",
										state: { fromDashboard: true },
									}}
								>
									<CardMedia
										component="img"
										alt="Value Stream Mapping + Scrum"
										height="140"
										image={VSMScrumImage}
										title="Value Stream Mapping + Scrum"
									/>
									<CardContent>
										<Typography gutterBottom variant="h5" component="h2">
											VSM + Scrum
										</Typography>
										<Typography
											variant="body2"
											color="textSecondary"
											component="div"
										>
											<Box fontWeight="Bold" display="inline">
												Descrivere il flusso di valore
											</Box>{" "}
											di un processo aziendale con la tecnica del Value Stream
											Mapping (VSM).
										</Typography>
									</CardContent>
								</CardActionArea>
								{/* <CardActions>
                <Button
                  component={Link}
                  to={{
                    pathname: "/pacchetti",
                    hash: "#VSMScrumSection",
                    state: { fromDashboard: true },
                  }}
                  size="small"
                  color="primary"
                >
                  Share
                </Button>
                <Button size="small" color="primary">
                  Durata e costi
                </Button>
              </CardActions> */}
							</Card>
						</GridItem>
						<GridItem className={classes.justifyCenter} xs={12} sm={6} md={2}>
							{" "}
							<Card className={classes.card}>
								<CardActionArea
									component={Link}
									to={{
										pathname: "/pacchetti",
										hash: "#TeatroPerDTSection",
										state: { fromDashboard: true },
									}}
								>
									<CardMedia
										component="img"
										alt="Teatro per il Design Thinking"
										height="140"
										image={TeatroPerDTImage}
										title="Teatro per il Design Thinking"
									/>
									<CardContent>
										<Typography gutterBottom variant="h5" component="h2">
											Teatro per il Design Thinking
										</Typography>
										<Typography
											variant="body2"
											color="textSecondary"
											component="div"
										>
											<Box fontWeight="Bold" display="inline">
												Applicare al meglio il Design Thinking
											</Box>{" "}
											utilizzando le tecniche teatrali.
										</Typography>
									</CardContent>
								</CardActionArea>
								{/* <CardActions>
                <Button
                  component={Link}
                  to={{
                    pathname: "/pacchetti",
                    hash: "#VSMScrumSection",
                    state: { fromDashboard: true },
                  }}
                  size="small"
                  color="primary"
                >
                  Share
                </Button>
                <Button size="small" color="primary">
                  Durata e costi
                </Button>
              </CardActions> */}
							</Card>
						</GridItem>
					</GridContainer>
				</Fade>
			</div>
		</div>
	);
}
