import React, { useEffect, useRef } from "react";

import Header from "../../components/Header/Header";
import HeaderLinks from "../../components/Header/HeaderLinks";
import MetodiSection from "./Sections/MetodiSection";
import VantaggioEsempi from "./Sections/VantaggioEsempi";
import ComplessitaEsempi from "./Sections/ComplessitaEsempi";
import InnovazioneEsempi from "./Sections/InnovazioneEsempi";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Slide from "@material-ui/core/Slide";
import Fade from "@material-ui/core/Fade";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "../../components/Parallax/Parallax.js";

import styles from "./serviziStyle.js";
import heroServiziImages from "../../assets/img/hero-servizi.jpg";

const useStyles = makeStyles(styles);

export default function Servizi(props) {
  const topRef = useRef(null);
  const vantaggioCompetitivoRef = useRef(null);
  const innovazioneRef = useRef(null);
  const complessitRef = useRef(null);

  const executeScrollToTop = () => {
    topRef.current.scrollIntoView();
    if (props.location.state) {
      switch (props.location.hash) {
        case "#innovazioneRef":
          innovazioneRef.current.scrollIntoView({ behavior: "smooth" });
          break;

        case "#vantaggioCompetitivoRef":
          vantaggioCompetitivoRef.current.scrollIntoView({
            behavior: "smooth",
          });
          break;

        case "#complessitRef":
          complessitRef.current.scrollIntoView({
            behavior: "smooth",
          });
          break;

        default:
          //Default will perform if all case’s fail

          break;
      }
    }
  };
  useEffect(executeScrollToTop);

  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div ref={topRef}>
      <Header
        color="transparent"
        brand="Webylia"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "white",
        }}
      />
      <Parallax medium filter image={heroServiziImages}>
        <div className={classes.parallaxContainer}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Fade in timeout={3000}>
                <h1 className={classes.parallaxTitle}>
                  Facilitatori di progetti di innovazione.
                </h1>
              </Fade>
              <Slide in direction="up" timeout={1500}>
                <div>
                  <Fade in timeout={3000}>
                    <h3>
                      Servizi e metodi per farvi diventare le api del vostro
                      ecosistema di business.
                    </h3>
                  </Fade>
                </div>
              </Slide>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <MetodiSection />
        <div ref={vantaggioCompetitivoRef} id="vantaggioCompetitivoRef">
          <VantaggioEsempi />
        </div>
        <div ref={complessitRef} id="complessitRef">
          <ComplessitaEsempi />
        </div>
        <div ref={innovazioneRef} id="innovazioneRef">
          <InnovazioneEsempi />
        </div>
      </div>
    </div>
  );
}
