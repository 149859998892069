import React, { useEffect, useRef } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components

import ContattiBox from "./ContattiBox";

import Header from "../../components/Header/Header";
import HeaderLinks from "../../components/Header/HeaderLinks";

import Grid from "@material-ui/core/Grid";
import Parallax from "../../components/Parallax/Parallax.js";
import Typography from "@material-ui/core/Typography";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";

import styles from "./contattiStyle";
import contattiImage from "../../assets/img/contattiImage3.jpg";
import mappaImage from "../../assets/img/mappa4.png";
const useStyles = makeStyles(styles);

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

export default function ContattiPage(props) {
  const topRef = useRef(null);

  const executeScrollToTop = () => {
    topRef.current.scrollIntoView();
  };
  useEffect(executeScrollToTop);

  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div ref={topRef}>
      <Header
        color="transparent"
        // routes={dashboardRoutes}
        brand="Webylia"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "white",
        }}
      />
      <Parallax xsm filter image={contattiImage}></Parallax>

      <div className={classes.rudjparallaxContainer}>
        <Grid container justify="center">
          <Grid item xs={12} sm={12} md={12} style={{ width: "auto" }}>
            <div className={classNames(classes.main, classes.mainRaised)}>
              <Grid container justify="center">
                <Grid item xs={12} sm={12} md={12}>
                  <div>
                    <ThemeProvider theme={theme}>
                      <Typography variant="h4" className={classes.titleH3}>
                        Conosciamoci
                      </Typography>
                    </ThemeProvider>
                  </div>
                </Grid>
              </Grid>
              <Grid container justify="center" style={{ margin: "unset" }}>
                <Grid item xs={11} sm={11} md={5}>
                  <Typography variant="h5" className={classes.titleH5}>
                    Per capire se siamo sulla stessa lunghezza d'onda scegli un
                    modo semplice:
                  </Typography>
                  <ContattiBox />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <img
                    alt="..."
                    src={mappaImage}
                    className={classes.paddingImage}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
