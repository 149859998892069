import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Collapse from "@material-ui/core/Collapse";
import CardMK from "../../../components/Card/Card.js";

import CardBody from "../../../components/Card/CardBody";
import Box from "@material-ui/core/Box";

import CardFooter from "../../../components/Card/CardFooter";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import reduceComplexityImage from "../../../assets/img/reduceComplexitySmall.jpg";
import ButtonBase from "@material-ui/core/ButtonBase";

import styles from "../metodiStyle";

const useStyles = makeStyles(styles);
export default function ScomposizioneeCard() {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 1800);

  const [expanded, setExpanded] = React.useState(false);
  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const classes = useStyles();

  return (
    <CardMK className={classes[cardAnimaton]}>
      <ButtonBase onClick={handleExpand}>
        <img
          title="ridurre la complessità"
          alt="ridurre la complessità"
          src={reduceComplexityImage}
          className={classes.cardImage}
        />
      </ButtonBase>
      <Button
        color="primary"
        onClick={handleExpand}
        className={classes.bottonCollaps}
      >
        Scomposizione della complessità
        <ExpandMoreIcon
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
        />
      </Button>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardBody>
          <Typography
            variant="body1"
            // style={{ marginTop: "30px" }}
            component="div"
            align="justify"
            variant="body1"
            gutterBottom
            className={classes.descrizioneVoce}
          >
            <Box style={{ marginBottom: "1rem" }}>
              Sempre più ci troviamo ad operare in un ambiente complesso, sia
              rispetto al mercato in continua evoluzione, che agli strumenti da
              utilizzare, ma anche all'interno della nostra stessa
              organizzazione.
            </Box>
            <Box style={{ marginBottom: "1rem" }}>
              Affrontata tutta di petto la complessità può sopraffarci.
            </Box>
            <Box style={{ marginBottom: "1rem" }}>
              La ricetta condivisa da Design Thinking, Agile e Lean è scomporre
              la complessità: scomporre i problemi, scomporre i processi,
              scomporre le fasi di lavorazione. Ad esempio la Lean scompone i
              processi produttivi attraverso il Value Stream Map e la catena di
              produzione in celle o postazioni, Agile scompone i prodotti in
              parti modulari e il Design Thinking suddivide in fasi distinte
              l’attività di problem solving.
            </Box>
          </Typography>
        </CardBody>
        <CardFooter className={classes.cardFooter}></CardFooter>
      </Collapse>
    </CardMK>
  );
}
