import React, { useEffect, useRef } from "react";

import Header from "../../components/Header/Header";
import HeaderLinks from "../../components/Header/HeaderLinks";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import MetodiBreadcrumbs from "./MetodiBreadcrumbs";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import CardMedia from "@material-ui/core/CardMedia";

import styles from "./metodiStyle.js";
import AgileImage from "../../assets/img/hero-agileMedium.jpg";
import agileVela from "../../assets/img/agileVela.jpg";
import agileIterazioni from "../../assets/img/agileIterazioni.jpg";

const useStyles = makeStyles(styles);

export default function AgilePage(props) {
  const topRef = useRef(null);
  const executeScrollToTop = () => {
    topRef.current.scrollIntoView();
  };
  useEffect(executeScrollToTop);

  const classes = useStyles();

  return (
    <div ref={topRef}>
      <Header
        color="transparent"
        brand="Webylia"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "white",
        }}
      />
      <Parallax xsmall filter image={AgileImage}>
        <div className={classes.parallaxContainer}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <h1 className={classes.parallaxTitle}>Agile</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <MetodiBreadcrumbs />
        <GridContainer
          justify="center"
          spacing={8}
          alignItems="center"
          alignContent="center"
          style={{ textAlign: "center" }}
        >
          <GridItem xs={12} sm={12} lg={4}>
            <img
              title="cicli Agile"
              alt="cicli Agile"
              src={agileIterazioni}
              className={classes.imageMax}
            />
          </GridItem>
          <GridItem xs={12} sm={12} lg={6}>
            <Typography
              variant="body1"
              // style={{ marginTop: "30px" }}
              component="div"
              align="justify"
              variant="body1"
              gutterBottom
              className={classes.descrizioneVoce}
            >
              <Box
                fontWeight="fontWeightMedium"
                fontStyle="italic"
                style={{ marginBottom: "1rem" }}
              >
                L’unica costante è il cambiamento. I piani sono un’utile
                illusione, la realtà ci sorprenderà sempre.{" "}
              </Box>
              <Box style={{ marginBottom: "1rem" }}>
                E' dalla constatazione di questa evidenza che nel 2001,
                nell'ambito dello sviluppo software nasce il pensiero Agile. Ma
                le metodologie Agile, nella loro ricerca della piena
                soddisfazione del cliente, si applicano ai più diversi ambiti,
                come ad esempio nella produzione aerospaziale, nella produzione
                di vino o birra, nella gestione di progetti di sicurezza e
                polizia, nella gestione di progetti di innovazione,
                nell'editoria, nel settore legale...
              </Box>
              <Box style={{ marginBottom: "1rem" }}>
                Agile è un metodo di gestione dei progetti e dei processi che si
                contrappone al modello “command e control”. E' un approccio{" "}
                <Box fontWeight="fontWeightMedium" display="inline">
                  collaborativo, organizzato per flussi di lavoro ciclici
                </Box>{" "}
                in cui le attività sono organizzate in iterazioni di breve
                durata (2-4 settimane) alla fine delle quali il risultato è un
                tangibile incremento di valore.
              </Box>
              <Box style={{ marginBottom: "2rem" }}>
                Con Agile si punta a generare valore (per il cliente e per
                l'azienda) il prima possibile. Non si aspetta che tutto il
                lavoro sia compiuto prima di consegnare e fatturare. Il
                "prodotto" deve essere modulare e ogni pezzo deve avere valore
                in sé e per sé.
              </Box>
            </Typography>
          </GridItem>
        </GridContainer>
        <div className={classes.stratiSection}>
          <GridContainer className={classes.gridTop}>
            <GridItem
              className={classes.textContainer}
              xs={false}
              sm={false}
              lg={1}
            ></GridItem>
            <GridItem className={classes.textContainer} xs={12} sm={12} lg={6}>
              <Typography
                variant="body1"
                style={{ marginTop: "2rem" }}
                component="div"
                align="justify"
                variant="body1"
                gutterBottom
                className={classes.descrizioneVoceDark}
              >
                <Box style={{ marginBottom: "1rem" }}>
                  L'adattabilità è un valore primario per Agile: ad ogni fase è
                  possibile modificare le priorità in base agli obiettivi e alle
                  esigenze del cliente e rianalizzare il lavoro svolto
                  nell'ottica del miglioramento continuo. In questo modo si{" "}
                  <Box fontWeight="fontWeightMedium" display="inline">
                    riduce al minimo il rischio di non centrare il reale
                    obiettivo
                  </Box>
                  .
                </Box>
                <Box style={{ marginBottom: "2rem" }}>
                  Gestire un progetto con Agile è come governare una barca a
                  vela: sappiamo in quale porto vogliamo arrivare, ma non ha
                  senso stendere un piano dettagliato di navigazione prima di
                  scendere in acqua. Dovremo adattarci alle condizioni di vento
                  e mare, modificando quando necessario sia l’andatura che la
                  velatura e potremmo dover cambiare spesso direzione.
                  L’equipaggio, sebbene ogni membro abbia la propria
                  specializzazione, deve collaborare, perché in porto si arriva
                  tutti insieme.
                </Box>
              </Typography>
            </GridItem>
            <GridItem xs={12} sm={12} lg={5}>
              <CardMedia
                className={classes.imageBanner}
                component="img"
                alt="Agile come navigare a vela"
                // height="300"
                image={agileVela}
                title="Agile come navigare a vela"
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
