import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Collapse from "@material-ui/core/Collapse";
import CardMK from "../../../components/Card/Card.js";

import CardBody from "../../../components/Card/CardBody";
import Box from "@material-ui/core/Box";

import CardFooter from "../../../components/Card/CardFooter";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import customerValue from "../../../assets/img/customerValueSmall.jpg";
import ButtonBase from "@material-ui/core/ButtonBase";

import styles from "../metodiStyle";

const useStyles = makeStyles(styles);
export default function FocalizzazioneCard() {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 1800);

  const [expanded, setExpanded] = React.useState(false);
  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const classes = useStyles();

  return (
    <CardMK className={classes[cardAnimaton]}>
      <ButtonBase onClick={handleExpand}>
        <img
          title="valore per il cliente"
          alt="valore per il cliente"
          src={customerValue}
          className={classes.cardImage}
        />
      </ButtonBase>
      <Button
        color="primary"
        onClick={handleExpand}
        className={classes.bottonCollaps}
      >
        Focalizzazione sul valore per il cliente
        <ExpandMoreIcon
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
        />
      </Button>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardBody>
          <Typography
            variant="body1"
            // style={{ marginTop: "30px" }}
            component="div"
            align="justify"
            variant="subtitle1"
            gutterBottom
            className={classes.descrizioneVoce}
          >
            <Box style={{ marginBottom: "1rem" }}>
              <Box fontWeight="fontWeightMedium" display="inline">
                Il ruolo centrale del cliente
              </Box>{" "}
              nella Lean, in Agile e nel Design Thinking risulta essere il
              principio guida fondamentale di tutte e tre le metodologie. Questo
              principio nella Lean è declinato come creazione del massimo valore
              per il cliente.
            </Box>
            <Box style={{ marginBottom: "1rem" }}>
              Così, in modo analogo, in Agile la massima priorità è soddisfare
              il cliente. Infine, scopo primario del Design Thinking è quello di
              realizzare la miglior esperienza possibile per l’utente.
            </Box>
            <Box style={{ marginBottom: "1rem" }}>
              Definizioni che appaiono sfumature di uno stesso concetto: il
              focus di ogni decisione e azione è il cliente. Un approccio che
              arriva ad essere talmente pervasivo da coinvolgere il cliente o
              utente direttamente nelle fasi di applicazione dei metodi.
            </Box>
            <Box style={{ marginBottom: "1rem" }}>
              Non sorprende quindi che queste tre metodologie possano mostrare
              molte similitudini nei loro percorsi verso questo obiettivo
              condiviso. Nei prossimi paragrafi vengono illustrati le loro
              molteplici affinità.
            </Box>
          </Typography>
        </CardBody>
        <CardFooter className={classes.cardFooter}></CardFooter>
      </Collapse>
    </CardMK>
  );
}
