import React from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
const LazyMailComp = React.lazy(() => import("./LazyMailComp"));
const LazyTelComp = React.lazy(() => import("./LazyTelComp"));

import Box from "@material-ui/core/Box";
import PermPhoneMsgIcon from "@material-ui/icons/PermPhoneMsg";
import ContactMailIcon from "@material-ui/icons/ContactMail";

import styles from "./contattiStyle";
const useStyles = makeStyles(styles);

export default function ContattiShortComp(props) {
  const [riferimento, setRiferimento] = React.useState(<>&nbsp;</>);
  const caricaMail = (event) => {
    event.preventDefault();
    setRiferimento(<LazyMailComp />);
  };

  const caricaTel = (event) => {
    event.preventDefault();
    setRiferimento(<LazyTelComp />);
  };
  caricaMail;
  const align = props.align || "left";
  const textColor = props.textColor || "#000";
  const classes = useStyles();
  const iconClasses = props.alternate
    ? classes.iconLink + " " + classes.iconColorAlternate
    : classes.iconLink;
  return (
    <Box component="div" m={1} style={{ textAlign: align, color: textColor }}>
      per saperne di più contattaci...
      <a href="" onClick={caricaTel}>
        <PermPhoneMsgIcon className={iconClasses} fontSize="small" />
      </a>
      <a href="" onClick={caricaMail}>
        <ContactMailIcon className={iconClasses} fontSize="small" />
      </a>
      <React.Suspense fallback={<div>loading...</div>}>
        <div className={iconClasses}>{riferimento}</div>
      </React.Suspense>
    </Box>
  );
}
