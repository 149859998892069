import React, { useEffect, useRef } from "react";

import Header from "../../components/Header/Header";
import HeaderLinks from "../../components/Header/HeaderLinks";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";

import MetodiBreadcrumbs from "./MetodiBreadcrumbs";
import styles from "./metodiStyle.js";
import DesignThinkImage from "../../assets/img/hero-dtMedium.jpg";
import dtVasiImage from "../../assets/img/dtVasi.png";
import dtHexImage from "../../assets/img/DT-hex.png";

const useStyles = makeStyles(styles);

export default function DesignThinkPage(props) {
  const topRef = useRef(null);
  const executeScrollToTop = () => {
    topRef.current.scrollIntoView();
  };
  useEffect(executeScrollToTop);

  const classes = useStyles();

  return (
    <div ref={topRef}>
      <Header
        color="transparent"
        brand="Webylia"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "white",
        }}
      />
      <Parallax small filter image={DesignThinkImage}>
        <div className={classes.parallaxContainer}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <h1 className={classes.parallaxTitle}>Design Thinking</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <MetodiBreadcrumbs />
        <GridContainer
          justify="center"
          spacing={8}
          alignItems="center"
          style={{ textAlign: "center" }}
        >
          <GridItem xs={12} sm={10} md={10} lg={4}>
            <img
              title="Design Thinking è un modo per scoprire i bisogni umani"
              alt="Design Thinking è un modo per scoprire i bisogni umani"
              src={dtVasiImage}
              className={classes.imageMax}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={6}>
            <Typography
              variant="body1"
              component="div"
              align="justify"
              variant="body1"
              gutterBottom
              className={classes.descrizioneVoce}
            >
              <Box
                fontWeight="fontWeightMedium"
                fontStyle="italic"
                style={{ marginBottom: "1rem" }}
              >
                "Il Design Thinking è un modo per scoprire i bisogni umani e
                creare nuove soluzioni utilizzando gli strumenti e il modo di
                pensare dei professionisti del design."
              </Box>
              <Box style={{ marginBottom: "1rem" }}>
                David Kelley, che è stato co-fondatore della società di design
                Ideo e ha contribuito a creare la scuola di Design Thinking
                presso la Stanford University nel 2004, in questa definizione
                del Design Thinking riesce a sintetizzare storia e attualità di
                questo approccio all’innovazione divenuto oggi indispensabile
                per affrontare contesti complessi e incerti.
              </Box>
              <Box style={{ marginBottom: "1rem" }}>
                Infatti il Design Thinking funziona meglio quando il problema
                non conduce ad una soluzione univoca ma quando diverse risposte
                potrebbero essere ugualmente valide, anche se non tutte
                ugualmente desiderabili.{" "}
              </Box>
              <Box style={{ marginBottom: "1rem" }}>
                L’approccio del Design Thinking si basa su un tentativo di
                comprendere meglio l'essere umano, tanto che il termine&nbsp;
                <Box fontWeight="fontWeightMedium" display="inline">
                  human-centered design
                </Box>{" "}
                (progettazione basata sull'uomo) può essere usato quasi come
                sinonimo di Design Thinking. Una visione che ha un doppio
                valore: quello di focus sull’utente e di attenzione all’interno
                del team di lavoro.{" "}
              </Box>
              <Box style={{ marginBottom: "2rem" }}>
                L'attenzione all'utente è l’elemento fondamentale. Un progetto
                di Design Thinking pone al centro le esigenze, i desideri, le
                aspirazioni e il contesto delle persone, cercando di realizzare
                per esse la miglior esperienza possibile. In questo modo si
                passa dalla sfida di progettare un vaso a quella di trovare il
                modo migliore affinché le persone possano abbellire la loro casa
                con i fiori.
              </Box>
            </Typography>
          </GridItem>
        </GridContainer>
        {/* <GridContainer justify="center" style={{ padding: "0px 1116px" }}>
          <GridItem xs={12} sm={12} md={6}></GridItem>
        </GridContainer> */}
        <div className={classes.stratiSection}>
          <GridContainer className={classes.gridTop} alignItems="center">
            <GridItem className={classes.textContainer} xs={12} sm={12} lg={6}>
              <Typography
                variant="body1"
                style={{ marginTop: "2rem" }}
                component="div"
                align="justify"
                variant="body1"
                gutterBottom
                className={classes.descrizioneVoceDark}
              >
                <Box>
                  Anche le persone che compongono il team di Design Thinking
                  sono importanti: la modalità di lavoro che viene promossa è
                  collaborativa e multidisciplinare.
                </Box>
                <Box style={{ marginBottom: "1rem" }}>
                  Questa è la più grande differenza tra il Design Thinking e i
                  progetti tradizionali nel mondo aziendale, che sono di solito
                  basati sulla cooperazione, per cui le persone possono lavorare
                  insieme ad un progetto, ma non sono necessariamente tutte
                  coinvolte nello stesso momento. Un team di Design Thinking
                  invece lavora e decide insieme, ovvero agisce in
                  collaborazione. Ogni membro del team è coinvolto in ogni fase
                  del processo.
                </Box>
                <Box style={{ marginBottom: "1rem" }}>
                  {" "}
                  Le fasi del Design Thinking aiutano a mantenere l’allineamento
                  del team all’obiettivo. Hanno lo scopo di separare l'analisi e
                  la comprensione di un problema dalla ricerca della soluzione.
                  Attraverso un’immersione nel contesto dell’utente (
                  <Box fontWeight="fontWeightBold" display="inline">
                    Empathize
                  </Box>
                  ) si cerca di identificare dei modelli e di raggiungere delle
                  intuizioni (
                  <Box fontWeight="fontWeightBold" display="inline">
                    Define
                  </Box>
                  ) che aiutino a sviluppare idee iniziali (
                  <Box fontWeight="fontWeightBold" display="inline">
                    Ideate
                  </Box>
                  ) per la realizzazione di prototipi (
                  <Box fontWeight="fontWeightBold" display="inline">
                    Prototype
                  </Box>
                  ) da usare per aumentare ulteriormente la chiarezza sul
                  risultato da ottenere (
                  <Box fontWeight="fontWeightBold" display="inline">
                    Test
                  </Box>
                  ). Tutto il processo è iterativo, poiché il confronto con
                  l’utente nella fase di Test è, di fatto, una nuova fase di
                  Empathize che fa proseguire il ciclo creativo.{" "}
                </Box>
                <Box style={{ marginBottom: "1rem" }}>
                  Data la sua vocazione a trattare contesti complessi, il Design
                  Thinking aiuta i team di lavoro a confrontarsi e a sentirsi a
                  proprio agio con l’incertezza. Promuove un atteggiamento di
                  sincera curiosità, di sollecitazione della creatività e di
                  propensione alla condivisione.
                </Box>
              </Typography>
            </GridItem>
            <GridItem xs={12} sm={12} lg={4}>
              <img
                title="Le fasi del Design Thinking"
                alt="Le fasi del Design Thinking"
                src={dtHexImage}
                className={classes.imageMax}
                style={{ padding: "0.5rem 0.5rem 1.5rem 0.5rem" }}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
