import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import Collapse from "@material-ui/core/Collapse";
import CardMK from "../../../../components/Card/Card.js";

import CardHeader from "../../../../components/Card/CardHeader";

import CardBody from "../../../../components/Card/CardBody";

import CardFooter from "../../../../components/Card/CardFooter";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlaneDeparture } from "@fortawesome/free-solid-svg-icons";

import styles from "../serviceStyle";

const useStyles = makeStyles(styles);
export default function VantaggioCompetitivoCard() {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 1200);

  const [expanded, setExpanded] = React.useState(false);
  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const classes = useStyles();

  return (
    <CardMK className={classes[cardAnimaton]}>
      <CardHeader
        color="primary"
        className={classes.cardHeader}
        onClick={handleExpand}
      >
        <div className={classes.socialLine}>
          <Button className={classes.headerIcon} onClick={handleExpand}>
            <FontAwesomeIcon
              icon={faPlaneDeparture}
              className={classes.cardIconHover}
            />
          </Button>
        </div>
        <h4 className={classes.cardH4}>
          Vuoi accrescere il tuo vantaggio competitivo e la posizione di mercato
        </h4>
      </CardHeader>
      <Button
        color="primary"
        onClick={handleExpand}
        className={classes.bottonCollaps}
      >
        Senti che è il momento di ...
        <ExpandMoreIcon
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
        />
      </Button>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardBody>
          <Typography variant="body2">
            migliorare il tuo livello di servizio
          </Typography>
          <Divider className={classes.divider} />
          <Typography variant="body2">
            accrescere il valore che porti ai tuoi clienti
          </Typography>
          <Divider className={classes.divider} />
          <Typography variant="body2">
            migliorare il tuo rapporto qualità/costo senza intaccare i margini
          </Typography>
          <Divider className={classes.divider} />
        </CardBody>
        <CardFooter className={classes.cardFooter}>
          <Button
            variant="contained"
            size="large"
            className={classes.cardBottonButton}
            component={Link}
            to={{
              pathname: "/servizi",
              hash: "#vantaggioCompetitivoRef",
              state: {
                fromDashboard: true,
                targetRef: "vantaggioCompetitivoRef",
              },
            }}
          >
            Cosa proponiamo noi?
          </Button>
        </CardFooter>
      </Collapse>
    </CardMK>
  );
}
