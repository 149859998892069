import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import VantaggioCompetitivoCard from "./ServiceSectionCards/VantaggioCompetitivoCard";
import ComplessitaCard from "./ServiceSectionCards/ComplessitaCard";
import InnovazioneCard from "./ServiceSectionCards/InnovazioneCard";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import CardMedia from "@material-ui/core/CardMedia";
import styles from "./serviceStyle";

import InnovationServiceImage from "../../../assets/img/InnovationServiceImage2.jpg";

const useStyles = makeStyles(styles);
export default function ServiceSection() {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 2000);

  const [expandedVC, setExpandedVC] = React.useState(false);
  const handleExpandVC = () => {
    setExpandedVC(!expandedVC);
  };

  const [expandedCompl, setExpandedCompl] = React.useState(false);
  const handleExpandCompl = () => {
    setExpandedVC(!expandedCompl);
  };

  const [expandedInnov, setExpandedInnov] = React.useState(false);
  const handleExpandInnov = () => {
    setExpandedVC(!expandedVC);
  };

  const classes = useStyles();

  const bull = <span className={classes.bullet}>•</span>;
  return (
    <div className={classes.section}>
      <GridContainer className={classes.gridTop}>
        <GridItem className={classes.textContainer} xs={12} sm={12} md={8}>
          <h3 className={classes.title} style={{ marginBottom: "4rem" }}>
            Possiamo esserti utili se...
          </h3>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <VantaggioCompetitivoCard />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <ComplessitaCard />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InnovazioneCard />
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <CardMedia
            className={classes.imageBanner}
            component="img"
            alt="Servizi per progetti di innovazione"
            height="200"
            image={InnovationServiceImage}
            title="Servizi per progetti di innovazione"
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
