/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import logoBigliaBN from "../../assets/img/logo/webyliaLogoBN.png";
import styles from "../../assets/jss/material-kit-react/components/footerStyle.js";

import Grid from "@material-ui/core/Grid";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import LocationOnIcon from "@material-ui/icons/LocationOn";
const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={8}>
            <div className={classes.left}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <Link to="/" className={classes.block}>
                    <img
                      src={logoBigliaBN}
                      alt="Webylia - consulenza aziendale"
                      className={classes.logoImg}
                    />
                  </Link>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <Link to="/chisiamo" className={classes.block}>
                    Chi siamo
                  </Link>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <Link to="/contatti" className={classes.block}>
                    Contatti
                  </Link>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <Link to="/cookie-policy" className={classes.block}>
                    Cookie Policy
                  </Link>
                </ListItem>
                {/* <ListItem className={classes.inlineBlock}>
                  <Link to="/in-costruzione" className={classes.block}>
                    Blog
                  </Link>
                </ListItem> */}
              </List>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} className={classes.right}>
            {/* <div style={{ textAlign: "right" }}>
              <ContactMailIcon
                color="disabled"
                fontSize="small"
                className={classes.icon}
              />
              <a href="mailto:info@webylia.com"> info@webylia.com</a>
            </div> */}
            {/* <div style={{ textAlign: "right" }}>
              &copy; {1900 + new Date().getYear()}, made with{" "}
              <Favorite className={classes.icon} /> by{" "}
              <Link to="/">Webylia</Link>.
            </div> */}
            <div className={classes.firma}>
              &copy; {1900 + new Date().getYear()}, p.i.: 04531720276
            </div>
          </Grid>
        </Grid>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
