import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import Header from "../../../components/Header/Header";
import HeaderLinks from "../../../components/Header/HeaderLinks";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import MetodiBreadcrumbs from "../MetodiBreadcrumbs";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import LinkMUI from "@material-ui/core/Link";

import Grid from "@material-ui/core/Grid";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";

import Button from "components/CustomButtons/Button.js";

import styles from "../metodiStyle.js";
import InsiemeImage from "../../../assets/img/hero-insiemeMedium.jpg";
import DTALinsiemeImage from "../../../assets/img/dtal-insieme.png";

const useStyles = makeStyles(styles);

export default function AgilePage(props) {
  const topRef = useRef(null);
  const executeScrollToTop = () => {
    topRef.current.scrollIntoView();
  };
  useEffect(executeScrollToTop);

  const classes = useStyles();

  return (
    <div ref={topRef}>
      <Header
        color="transparent"
        brand="Webylia"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "white",
        }}
      />
      <Parallax medium filter image={InsiemeImage}>
        <div className={classes.parallaxContainer}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <h1 className={classes.parallaxTitle}>
                Design Thinking, Agile e Lean insieme
              </h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <MetodiBreadcrumbs />
        <GridContainer
          justify="center"
          spacing={8}
          alignItems="center"
          alignContent="center"
          style={{ textAlign: "center" }}
        >
          <GridItem xs={12} sm={12} lg={4}>
            <img
              title="Design Thinking Agile e Lean insieme"
              alt="Design Thinking Agile e Lean insieme"
              src={DTALinsiemeImage}
              className={classes.imageMax}
            />
          </GridItem>
          <GridItem xs={12} sm={12} lg={6}>
            <Typography
              variant="body1"
              // style={{ marginTop: "30px" }}
              component="div"
              align="justify"
              variant="body1"
              gutterBottom
              className={classes.descrizioneVoce}
            >
              <Box
                fontWeight="fontWeightMedium"
                fontStyle="italic"
                style={{ marginBottom: "1rem" }}
              >
                <LinkMUI href="/metodi/designthinking">
                  {" "}
                  Design Thinking
                </LinkMUI>
                ,<LinkMUI href="/metodi/agile"> Agile</LinkMUI> e
                <LinkMUI href="/metodi/lean"> Lean</LinkMUI>: come funzionano
                insieme.{" "}
              </Box>
              <Box style={{ marginBottom: "1rem" }}>
                Molteplici sono i modi in cui puoi abbinare{" "}
                <LinkMUI href="/metodi/designthinking">
                  {" "}
                  Design Thinking
                </LinkMUI>
                ,<LinkMUI href="/metodi/agile"> Agile</LinkMUI> e
                <LinkMUI href="/metodi/lean"> Lean</LinkMUI>. Nella sezione
                <LinkMUI href="/servizi"> Servizi</LinkMUI> abbiamo riportato
                una serie di esempi e situazione in cui la soluzione è veicolata
                dall'abbinamento di queste metodologie.
              </Box>
              <Box style={{ marginBottom: "1rem" }}>
                Ad esempio supponiamo che tu non riesca a prevedere i volumi di
                vendita in modo efficace e abbia l’impressione che nella tua
                azienda Vendite e Produzione parlino lingue diverse.
                Probabilmente la gestione della domanda, sia in termini di
                previsione dei volumi di vendita, che di evasione del
                portafoglio ordini, è migliorabile.
              </Box>
              <Box style={{ marginBottom: "1rem" }}>
                Per riuscirci dovrai fare in modo che i processi di vendita e di
                produzione siano integrati tra loro.
              </Box>
              <Box style={{ marginBottom: "1rem" }}>In che modo?</Box>
              <Box style={{ marginBottom: "1rem" }}>
                Attraverso un percorso di analisi e costruzione suddivisibile
                sostanzialmente in quattro fasi: <br />
                1.comprendere con chiarezza come l’insieme Vendite-Produzione
                opera (Value Stream Map - Lean);
                <br />
                2. definire le reali aspettative del cliente in termini di
                livello di servizio (Design Thinking);
                <br />
                3.disegnare il modo in cui l’insieme Vendite-Produzione dovrebbe
                operare a fronte delle aspettative del cliente(Value Stream Map
                - Lean);
                <br />
                4.realizzare un processo integrato Vendite-Produzione di
                pianificazione operativa (S&OP Lean).
              </Box>
              <Box style={{ marginBottom: "1rem" }}>
                Il progetto per realizzare questa trasformazione sarà un Agile
                Scrum.
              </Box>
            </Typography>
          </GridItem>
        </GridContainer>

        <Grid
          spacing={1}
          container
          justify="center"
          alignContent="center"
          alignItems="center"
          style={{ padding: "0rem 1rem 3rem 1rem " }}
        >
          <Grid item>
            <Button
              size="lg"
              fullWidth
              variant="text"
              component={Link}
              to="/metodi/in_comune"
              style={{
                backgroundColor: "#f8d300",
                color: "#222222",
                fontSize: "1rem",
                marginTop: "2rem",
                minHeight: "4rem",
                minWidth: "24vw",
              }}
            >
              <Box component="div" whiteSpace="normal">
                Design Thinking, Agile e Lean: cosa hanno in comune?
              </Box>
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
