import React, { useEffect, useState, useRef } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "./landingPageStyle.js";
import heroImages from "../../assets/img/hero-rafting_mirror.png";

// Sections for this page
import MetodiSection from "./Sections/MetodiSection.js";
import SpikeSection from "./Sections/SpikeSection";
import ServiceSection from "./Sections/ServiceSection";
import Slide from "@material-ui/core/Slide";
import Fade from "@material-ui/core/Fade";

import Header from "../../components/Header/Header";
import HeaderLinks from "../../components/Header/HeaderLinks";

// const dashboardRoutes = [];

const useStyles = makeStyles(styles);
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

export default function LandingPage(props) {
  const topRef = useRef(null);
  const spikeSectionRef = useRef(null);

  // scrolla al top quando viene ricaricata
  const executeScrollToTop = () => topRef.current.scrollIntoView();
  useEffect(executeScrollToTop);

  const [showButton, setShowButton] = useState(true);
  // setTimeout(() => {
  //   setShowButton(true);
  // }, 1600);

  const executeSpikeSectionScroll = () =>
    spikeSectionRef.current.scrollIntoView({ behavior: "smooth" });
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div ref={topRef}>
      <Header
        color="transparent"
        // routes={dashboardRoutes}
        brand="Webylia"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "white",
        }}
        // {...rest}
      />
      <Parallax
        filter
        image={heroImages}
        alt="Design Thinking. Lean. Agile. Digitalizzazione."
        title="Design Thinking. Lean. Agile. Digital Solution."
      >
        <div className={classes.parallaxContainer}>
          {/* <ThemeProvider theme={theme}> */}
          {/* <Typography variant="h3"> */}
          {/* FACILITIAMO L'INNOVAZIONE NELLE AZIENDE */}
          {/* </Typography> */}
          {/* </ThemeProvider> */}
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              {/* <Fade in timeout={3000}>
                <h3 className={classes.title}>
                  FACILITIAMO L'INNOVAZIONE NELLE AZIENDE
                  LEAN. AGILE. DESIGN THINKING. DIGITAL SOLUTION.
                </h3>
              </Fade> */}
              <Fade in timeout={3000}>
                <div>
                  <Slide in direction="up" timeout={1500}>
                    <h1 className={classes.title}>
                      Facilitiamo l'innovazione nelle aziende.
                    </h1>
                  </Slide>
                </div>
              </Fade>

              <br />
              <Fade
                in={showButton}
                timeout={1000}
                style={{ transitionDelay: "1000ms" }}
              >
                <Button
                  style={{
                    backgroundColor: "#f8d300",
                    color: "#222222",
                  }}
                  onClick={executeSpikeSectionScroll}
                >
                  Cosa possiamo fare per te oggi?
                </Button>
              </Fade>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8}>
              <h2 className={classes.titleSection}>
                {/* La partecipazione delle persone è la forza creativa che spinge
                le aziende al successo. */}
                In un contesto in rapida evoluzione ci vuole partecipazione e
                metodo per vincere.
              </h2>
              <h3 className={classes.description} style={{ fontWeight: 400 }}>
                Per questo vi aiutiamo ad applicare il metodo con entusiasmo,{" "}
                <br />
                trasformando il lavoro in valore e l’impegno in gioia.
              </h3>
            </GridItem>
          </GridContainer>
        </div>
        <ServiceSection />
        <MetodiSection />

        <div ref={spikeSectionRef}>
          <SpikeSection />
        </div>
      </div>
    </div>
  );
}
