import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import ContattiShortComp from "../../Contatti/ContattiShortComp";

// @material-ui/icons
import AvTimer from "@material-ui/icons/AvTimer";
import EuroSymbol from "@material-ui/icons/EuroSymbol";
import EmojiEvents from "@material-ui/icons/EmojiEvents";
import People from "@material-ui/icons/People";
import ListAlt from "@material-ui/icons/ListAlt";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Link from "@material-ui/core/Link";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";

import Lego4AgileImage from "../../../assets/img/scrum-simulation-with-lego-agile-game-4-638.jpg";
import styles from "../pacchettiStyle.js";

const useStyles = makeStyles(styles);

export default function Lego4AgileSection() {
	const classes = useStyles();
	const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
	return (
		<div className={classes.sectionAlternate}>
			<GridContainer justify="center">
				<GridItem xs={12} sm={12} md={10}>
					<h2 className={classes.title + " " + classes.textColorAlternate}>
						Lego4Agile
					</h2>
					<h4 className={classes.title2 + " " + classes.textColorAlternate}>
						Corso di introduzione ai principi{" "}
						<Link href="/metodi/agile" color="error">
							Agile
						</Link>{" "}
						con simulazione Lego
					</h4>
					<GridContainer justify="center">
						<GridItem xs={12} sm={12} md={4}>
							<List>
								<ListItem alignItems="flex-start">
									<ListItemAvatar>
										<AvTimer color="error" fontSize="large" />
									</ListItemAvatar>
									<ListItemText
										disableTypography
										secondary={
											<React.Fragment>
												<Typography
													component="span"
													variant="h6"
													className={classes.textColorAlternate}
													color="inherit"
												>
													Durata:
												</Typography>
												<Typography
													component="span"
													variant="body1"
													className={
														classes.descrizioneVoce +
														" " +
														classes.textColorAlternate
													}
													className={classes.textColorAlternate}
												>
													4 ore
												</Typography>
											</React.Fragment>
										}
									/>
								</ListItem>
								<hr className={classes.hrMargin} />
								{/* <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <EuroSymbol
                      className={classes.successColor}
                      fontSize="large"
                    />
                  </ListItemAvatar>
                  <ListItemText
                    disableTypography
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="h6"
                          className={classes.textColorAlternate}
                        >
                          Costo:
                        </Typography>
                        <Typography
                          component="span"
                          variant="body1"
                          className={
                            classes.descrizioneVoce +
                            " " +
                            classes.textColorAlternate
                          }
                        >
                          €500 + iva per gruppo
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <hr className={classes.hrMargin} /> */}
								<ListItem alignItems="flex-start">
									<ListItemAvatar>
										<EmojiEvents
											className={classes.warningColor}
											fontSize="large"
										/>
									</ListItemAvatar>
									<ListItemText
										disableTypography
										secondary={
											<React.Fragment>
												<Typography
													component="p"
													variant="h6"
													// className={classes.inline}
													className={classes.textColorAlternate}
												>
													Obiettivi:
												</Typography>
												<Typography
													component="p"
													variant="body1"
													className={
														classes.descrizioneVoceLista +
														" " +
														classes.textColorAlternate
													}
												>
													Sperimentare come le pratiche Agile Scrum promuovano
													la collaborazione ed il continuo miglioramento per il
													raggiungimento di un obiettivo comune.
												</Typography>
												<Typography
													component="p"
													variant="body1"
													className={
														classes.descrizioneVoceLista +
														" " +
														classes.textColorAlternate
													}
												>
													Valorizzare l'importanza del gioco di squadra rispetto
													all'azione individuale.
												</Typography>
												<Typography
													component="p"
													variant="body1"
													className={
														classes.descrizioneVoceLista +
														" " +
														classes.textColorAlternate
													}
												>
													Godersi i benefici della collaborazione invece che
													stressarsi nella competizione.
												</Typography>
												<Typography
													component="p"
													variant="body1"
													className={
														classes.descrizioneVoceLista +
														" " +
														classes.textColorAlternate
													}
												>
													Imparare a ridurre il rischio procedendo per passi
													invece di giocarsi il tutto per tutto in un'unica
													azione.
												</Typography>
											</React.Fragment>
										}
									/>
								</ListItem>

								<hr className={classes.hrMargin} />
							</List>
						</GridItem>
						<GridItem xs={12} sm={12} md={4}>
							<List>
								<ListItem alignItems="flex-start">
									<ListItemAvatar>
										<People style={{ color: "#ddd" }} fontSize="large" />
									</ListItemAvatar>
									<ListItemText
										disableTypography
										secondary={
											<React.Fragment>
												<Typography
													component="span"
													variant="h6"
													// className={classes.inline}
													className={classes.textColorAlternate}
												>
													Destinatari:
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={
														classes.descrizioneVoceLista +
														" " +
														classes.textColorAlternate
													}
												>
													{
														"Team di progetto che vogliano accrescere la loro capacità di collaborazione e l’opportunità di trarre maggior soddisfazione dal proprio lavoro."
													}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={
														classes.descrizioneVoceLista +
														" " +
														classes.textColorAlternate
													}
												>
													{
														"L’ideale sono 2-3 team di 4-6 persone (corrispondono a 8-18 persone)."
													}
												</Typography>
											</React.Fragment>
										}
									/>
								</ListItem>
								<hr className={classes.hrMargin} />
								<ListItem alignItems="flex-start">
									<ListItemAvatar>
										<ListAlt className={classes.infoColor} fontSize="large" />
									</ListItemAvatar>
									<ListItemText
										disableTypography
										secondary={
											<React.Fragment>
												<Typography
													component="div"
													variant="h6"
													// className={classes.inline}
													className={classes.textColorAlternate}
												>
													{"Programma: "}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={
														classes.descrizioneVoceLista +
														" " +
														classes.textColorAlternate
													}
												>
													{"Veloce panoramica sui "}
													<Link href="/metodi/agile" color="error">
														principi Agile
													</Link>
													{" e sui metodi Scrum."}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={
														classes.descrizioneVoceLista +
														" " +
														classes.textColorAlternate
													}
												>
													{"Definizione macro obiettivi della simulazione."}
												</Typography>
												<Typography
													// component="ul"
													variant="body2"
													className={
														classes.descrizioneVoceLista +
														" " +
														classes.textColorAlternate
													}
												>
													3 cicli (Sprint) di sviluppo costituiti da:
												</Typography>
												<Typography
													// component="ol"
													variant="body1"
													className={
														classes.descrizioneVoceListaIndentata +
														" " +
														classes.textColorAlternate
													}
												>
													- pianificazione delle attività.
												</Typography>
												<Typography
													// component="ol"
													variant="body1"
													className={
														classes.descrizioneVoceListaIndentata +
														" " +
														classes.textColorAlternate
													}
												>
													- esecuzione delle attività.
												</Typography>
												<Typography
													// component="ol"
													variant="body1"
													className={
														classes.descrizioneVoceListaIndentata +
														" " +
														classes.textColorAlternate
													}
												>
													- revisione dei risultati.
												</Typography>
												<Typography
													component="div"
													variant="body1"
													className={
														classes.descrizioneVoceLista +
														" " +
														classes.textColorAlternate
													}
												>
													{"Debriefing."}
												</Typography>
											</React.Fragment>
										}
									/>
								</ListItem>
								<hr className={classes.hrMargin} />
								<ContattiShortComp textColor="#fff" align="right" alternate />
							</List>
						</GridItem>
						<GridItem xs={12} sm={12} md={4}>
							<img
								alt="Lego for Agile"
								title="Lego for Agile"
								src={Lego4AgileImage}
								className={navImageClasses}
							/>
						</GridItem>
					</GridContainer>
				</GridItem>
			</GridContainer>
		</div>
	);
}
