import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components

import Card from "@material-ui/core/Card";
import CardFooter from "../../../components/Card/CardFooter";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "../chiSiamoPageStyle";

import Link from "@material-ui/core/Link";
import team1 from "assets/img/faces/raffaele.png";
import team2 from "assets/img/faces/Silvia_2.png";
import team3 from "assets/img/faces/rudj2.jpg";
import apicsImage from "assets/img/APICS.png";
import accrediaImage from "assets/img/Accredia-Certing.png";
import awsImage from "../../../assets/img/AWS2020.png";
import ibmdtImage from "../../../assets/img/IBM_DT.png";
import iilbcImage from "assets/img/IILBC.png";
import sfcImage from "../../../assets/img/SFC.png";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
const LazyMailComp = React.lazy(() => import("../../Contatti/LazyMailComp"));

const useStyles = makeStyles(styles);

export default function TeamSection() {
	const [riferimentoMail, setRiferimentoMail] = React.useState(false);
	const [riferimentoMailNome, setRiferimentoMailNome] = React.useState(false);
	const caricaMail = (email) => (event) => {
		event.preventDefault();
		setRiferimentoMail(<LazyMailComp email={email} />);
		setRiferimentoMailNome(email);
	};

	const classes = useStyles();
	const imageClasses = classNames(
		classes.imgRaised,
		classes.imgRoundedCircle,
		// classes.imgFluid,
		classes.imageProfile
	);
	return (
		<>
			<div className={classes.section}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={5}>
						<h2 className={classes.title}>La nostra esperienza</h2>
						<h4 className={classes.leftList}>
							Il nostro team è composto da specialisti con un’esperienza
							ventennale in:
						</h4>
						<ul className={classes.leftList}>
							<li>analisi, progettazione e sviluppo di sistemi gestionali</li>
							<li>progetti di innovazione tecnologica</li>
							<li>analisi vendite e marketing</li>
							<li>progetti di formazione ed edutainment</li>
							<li>
								gestione e ottimizzazione dei processi di pianificazione e
								programmazione operativa (demand planning, produzione,
								magazzini, approvvigionamento) in contesti organizzativi
								tradizionali e <Link href="/metodi/lean">Lean</Link>.
							</li>
						</ul>
					</GridItem>
					<GridItem xs={12} sm={12} md={1}></GridItem>
					<GridItem xs={12} sm={12} md={4}>
						<h2 className={classes.title}>I nostri valori</h2>
						<h4 className={classes.leftList}>&nbsp; </h4>
						<ul className={classes.leftList}>
							<li>La felicità e la soddisfazione delle persone.</li>
							<li>La creAttività di squadra.</li>
							<li>L'ascolto e la condivisione.</li>
							<li>La sostenibilità del lavoro.</li>
							<li>Il pragmatismo piuttosto che la teoria.</li>
							<li>Il miglioramento continuo.</li>
						</ul>
					</GridItem>
					<GridItem xs={12} sm={12} md={5}>
						<p className={classes.centerSentence}>
							Negli ultimi anni abbiamo maturato la convinzione che l'approccio{" "}
							<Link href="/metodi/designthinking">Design Thinking</Link> /{" "}
							<Link href="/metodi/agile">Agile</Link> realizzi al meglio il
							nostro principio di porre il cliente al centro di ogni scelta
							operativa e progettuale.
						</p>
					</GridItem>
				</GridContainer>
				<GridContainer className={classes.avatarConteiner}>
					<GridItem xs={12} sm={12} md={3}>
						<Card>
							<GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
								<img
									src={team1}
									alt="Raffaele De Marco"
									className={imageClasses}
								/>
							</GridItem>
							<h4 className={classes.cardTitle}>
								Raffaele De Marco
								<br />
								<small className={classes.smallTitle}>
									Supply Chain Management
								</small>
							</h4>
							<CardFooter className={classes.justifyCenter}>
								{riferimentoMailNome == "raffaele" ? (
									<React.Suspense fallback={<div>loading...</div>}>
										<div className={classes.iconLink}>{riferimentoMail}</div>
									</React.Suspense>
								) : (
									<IconButton
										onClick={caricaMail("raffaele")}
										color="primary"
										size="small"
										variant="outlined"
									>
										<i className={classes.socials + " fas fa-at"} />
									</IconButton>
								)}
								&nbsp;
								<a
									className={classes.margin5}
									href="https://www.linkedin.com/in/raffaele-de-marco-63a52349/"
									target="_blank"
								>
									<i className={classes.socials + " fab fa-linkedin"} />
								</a>
							</CardFooter>
						</Card>
					</GridItem>
					<GridItem xs={12} sm={12} md={3}>
						<Card>
							<GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
								<img src={team2} alt="Silvia Nanni" className={imageClasses} />
							</GridItem>
							<h4 className={classes.cardTitle}>
								Silvia Nanni
								<br />
								<small className={classes.smallTitle}>
									Design Thinking & Digital Solution
								</small>
							</h4>
							<CardFooter className={classes.justifyCenter}>
								{riferimentoMailNome == "silvia" ? (
									<React.Suspense fallback={<div>loading...</div>}>
										<div className={classes.iconLink}>{riferimentoMail}</div>
									</React.Suspense>
								) : (
									<IconButton
										onClick={caricaMail("silvia")}
										color="primary"
										size="small"
										variant="outlined"
									>
										<i className={classes.socials + " fas fa-at"} />
									</IconButton>
								)}
								&nbsp;
								<a
									className={classes.margin5}
									href="https://www.linkedin.com/in/silvia-nanni-6914712b/"
									target="_blank"
								>
									<i className={classes.socials + " fab fa-linkedin"} />
								</a>
							</CardFooter>
						</Card>
					</GridItem>
					<GridItem xs={12} sm={12} md={3}>
						<Card>
							<GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
								<img
									src={team3}
									alt="Rudj Maria Todaro"
									className={imageClasses}
								/>
							</GridItem>
							<h4 className={classes.cardTitle}>
								Rudj Maria Todaro
								<br />
								<small className={classes.smallTitle}>
									Agile & Innovation Manager
								</small>
							</h4>
							<CardFooter className={classes.justifyCenter}>
								{riferimentoMailNome === "rudj" ? (
									<React.Suspense fallback={<div>loading...</div>}>
										<div className={classes.iconLink}>{riferimentoMail}</div>
									</React.Suspense>
								) : (
									<IconButton
										onClick={caricaMail("rudj")}
										color="primary"
										size="small"
										variant="outlined"
									>
										<i className={classes.socials + " fas fa-at"} />{" "}
									</IconButton>
								)}
								&nbsp;
								<a
									className={classes.margin5}
									href="https://www.linkedin.com/in/rudjmariatodaro/"
									target="_blank"
								>
									<i className={classes.socials + " fab fa-linkedin"} />
								</a>
							</CardFooter>
						</Card>
					</GridItem>
				</GridContainer>
				<Divider />
			</div>
			<div className={classes.section}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={6}>
						<h2 className={classes.title}>Le nostre certificazioni</h2>
						<br />
						<br />
					</GridItem>
				</GridContainer>
				<GridContainer alignItems="center">
					<GridItem xs={12} sm={12} md={2}>
						<img
							src={apicsImage}
							alt="Certificazione Apics"
							className={classes.imageCertificazione}
						/>
					</GridItem>
					<GridItem xs={12} sm={12} md={2}>
						<img
							src={accrediaImage}
							alt="Certificazione Accredia-Certing"
							className={classes.imageCertificazione}
						/>
					</GridItem>
					<GridItem xs={12} sm={12} md={2}>
						<img
							src={iilbcImage}
							alt="Certificazione IILBC"
							className={classes.imageCertificazione}
						/>
					</GridItem>
				</GridContainer>
				<br />
				<br />
				<GridContainer alignItems="center">
					<GridItem xs={12} sm={12} md={2}>
						<img
							src={awsImage}
							alt="Certificazione Amazon Web Service Practitioner"
							className={classes.imageCertificazione}
						/>
					</GridItem>
					<GridItem xs={12} sm={12} md={2}>
						<img
							src={sfcImage}
							alt="Certificazione Scrum Fundamentals"
							className={classes.imageCertificazione}
						/>
					</GridItem>
					<GridItem xs={12} sm={12} md={2}>
						<img
							src={ibmdtImage}
							alt="Certificazione IBM Design Thinking Practitioner"
							className={classes.imageCertificazione}
						/>
					</GridItem>
				</GridContainer>
			</div>
		</>
	);
}
