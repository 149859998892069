import React from "react";
// @material-ui/core components

import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

import Typography from "@material-ui/core/Typography";

import Box from "@material-ui/core/Box";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "../serviziStyle.js";

const useStyles = makeStyles(styles);

export default function MetodiSection() {
  const classes = useStyles();
  return (
    <div className={classes.sectionTop}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={11}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8}>
              <Typography
                component="div"
                align="justify"
                variant="body1"
                gutterBottom
                className={classes.descrizioneVoce}
              >
                Ci definiamo
                <Box fontWeight={500} display="inline">
                  {" "}
                  facilitatori
                </Box>{" "}
                invece che consulenti
                <Box fontWeight={500} display="inline">
                  {" "}
                  perché solo voi conoscete a fondo il contesto
                </Box>{" "}
                umano, organizzativo e tecnologico in cui vi muovete.
              </Typography>
              <Typography
                component="div"
                align="justify"
                variant="body1"
                gutterBottom
                className={classes.descrizioneVoce}
              >
                Possiamo però aiutarvi a diventare le api del vostro ecosistema
                di business, che non si limitano a succhiare il nettare dai
                fiori, ma li impollinano, affinché possano moltiplicarsi e
                portare nuovi frutti e raccolti più ricchi.
              </Typography>
              <Typography
                component="div"
                align="justify"
                variant="body1"
                gutterBottom
                className={classes.descrizioneVoce}
              >
                L’obiettivo è{" "}
                <Box fontWeight={500} display="inline">
                  {" "}
                  generare innovazione e valore in sintonia con i vostri partner
                </Box>{" "}
                - clienti, fornitori o altri interlocutori - in una logica
                costruttiva di reciprocità, fidelizzazione e crescita.
              </Typography>
              <Typography
                component="div"
                align="justify"
                variant="body1"
                gutterBottom
                className={classes.descrizioneVoce}
              >
                Per fare questo proponiamo un insieme di metodi che coinvolgano
                le risorse esistenti con un approccio di integrazione graduale,
                riducendo il rischio di fallimento e stimolando soluzioni
                creative in un contesto di miglioramento continuo.
              </Typography>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}
