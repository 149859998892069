import React, { useRef, useState } from "react";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";

import Link from "@material-ui/core/Link";
import classNames from "classnames";

// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Typography from "@material-ui/core/Typography";
import MuiAccordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styles from "../serviziStyle";

const useStyles = makeStyles(styles);
const Accordion = withStyles({
  expanded: {},
})(MuiAccordion);

import heroComplessitaImages from "../../../assets/img/complexity.jpg";

export default function ComplessitaEsempi() {
  const classes = useStyles();
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={4}>
          <img
            alt="Consulenza per la gestione della complessità"
            title="Consulenza per la gestione della complessità"
            src={heroComplessitaImages}
            className={navImageClasses}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={1}></GridItem>
        <GridItem xs={12} sm={12} md={5}>
          <h2 className={classes.sectionTitle}>Governare la complessità</h2>
          <h3 className={classes.sectionSubTitle}>Esempi di intervento</h3>
          <Accordion
            className={classes.accordionMargin}
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.accorditionHeading}
            >
              <Typography
                align="left"
                variant="h6"
                className={classes.accordionTitle}
              >
                Liberarsi della zavorra
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                align="left"
                variant="body1"
                className={classes.title}
              >
                Vuoi liberarti degli sprechi ma non ti è chiaro dove puoi
                intervenire? Vuoi migliorare per dare più valore al tuo business
                e ai tuoi clienti?
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography align="justify" display="block">
                Noi possiamo sostenerti lungo questo percorso, identificando
                insieme a te gli elementi strategici che ti permetteranno di
                produrre maggior valore per il tuo business e per i tuoi
                clienti.
                <br />
                Ti aiuteremo a tracciare un percorso incrementale che minimizzi
                il rischio di fallimento e sia capace di produrre valore
                gradualmente, ma con continuità, durante tutto il suo sviluppo.
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography align="justify">In che modo?</Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography align="justify">
                Ispirandoci ai principi e alle metodologie Lean ti seguiremo
                passo passo nell’implementazione delle pratiche logistiche e
                produttive più efficienti ed efficaci rispetto al tuo contesto
                commerciale, organizzativo e tecnologico.
                <br />
                <br />
                Se necessario, ti aiuteremo anche a sfruttare in modo “snello”
                gli strumenti digitali più adatti.
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography variant="subtitle2" display="block">
                <Link href="/metodi/lean" color="secondary">
                  Lean
                </Link>
                {" + "}
                <Link href="/metodi/agile" color="secondary">
                  Agile
                </Link>
                {" + Digitalizzazione"}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className={classes.accordionMargin}
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
              className={classes.accorditionHeading}
            >
              <Typography
                align="left"
                variant="h6"
                className={classes.accordionTitle}
              >
                Armonia tra vendite e produzione
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                align="left"
                variant="body1"
                className={classes.title}
              >
                L’impulsività e la variabilità dei mercati ti impedisce di
                gestire la catena di produzione senza strappi e sovraccarichi?
                Non riesci a prevedere i volumi di vendita e il mix di
                produzione in modo efficace ed efficiente? Hai l’impressione che
                nella tua azienda Vendite e Produzione parlino lingue diverse e
                non si comprendano?
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography align="justify" display="block">
                Gestire la domanda nel modo corretto, sia in termini di
                previsione dei volumi di vendita, che di evasione del
                portafoglio ordini, è di fondamentale importanza per la
                sopravvivenza e il successo aziendale, soprattutto oggi, in un
                contesto di mercato in cui il vantaggio competitivo si realizza
                tramite bassi tempi di risposta al cliente e alti livelli di
                servizio.
                <br />
                <br />
                Questi obiettivi non possono essere raggiunti se non si
                integrano tra loro i processi di vendita e di produzione nel
                quadro di una strategia di business chiara e definita.
                <br />
                <br />
                Far convergere le esigenze di chi vende e di chi produce su
                piani operativi chiari, condivisi e bilanciati permette, innanzi
                tutto, di tenere alto il livello di soddisfazione del cliente
                rispetto alla puntualità e alla qualità delle consegne.
                <br />
                <br />
                La condivisione dei piani di vendita e produzione conduce,
                inoltre, all’eliminazione delle rotture di stock, alla riduzione
                delle scorte (perché non vengono realizzati prodotti non
                richiesti) e ad una più agevole stabilizzazione dei carichi e
                dei ritmi di produzione.
                <br />
                <br />
                Noi possiamo sostenerti lungo questo percorso.
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography align="justify">In che modo?</Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography align="left">
                Gli obiettivi descritti sopra possono essere raggiunti
                attraverso un percorso di analisi e costruzione suddivisibile
                sostanzialmente in quattro fasi:
                <br />
                &nbsp; &nbsp;1. comprendere con chiarezza come l’insieme
                Vendite-Produzione opera;
                <br />
                &nbsp; &nbsp;2. definire le reali aspettative del cliente in
                termini di livello di servizio;
                <br />
                &nbsp; &nbsp;3. disegnare il modo in cui l’insieme
                Vendite-Produzione dovrebbe operare a fronte delle aspettative
                del cliente;
                <br />
                &nbsp; &nbsp;4. realizzare un processo integrato
                Vendite-Produzione di pianificazione operativa (S&OP).
                <br />
                Vi proponiamo, inoltre, di realizzare questa trasformazione in
                modalità Agile: ossia adottando pratiche di gestione dei
                progetti che implicano gradualità e flessibilità di azione,
                stretta collaborazione inter-funzionale e minimizzazione dei
                rischi di fallimento del progetto.
                <br />
                <br />
                Se necessario, possiamo aiutarvi a consolidare questa
                trasformazione anche a livello di digitalizzazione dei processi.
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography variant="subtitle2" display="block">
                S&OP
                {" + "}
                <Link href="/metodi/agile" color="secondary">
                  Agile
                </Link>
                {" + "}
                <Link href="/metodi/designthinking" color="secondary">
                  [+ Design Thinking]
                </Link>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </GridItem>
      </GridContainer>
    </div>
  );
}
