import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import BlurLinearIcon from "@material-ui/icons/BlurLinear";
import FlipCameraAndroidIcon from "@material-ui/icons/FlipCameraAndroid";
import ScreenShareIcon from "@material-ui/icons/ScreenShare";
import WbIncandescentIcon from "@material-ui/icons/WbIncandescent";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import Button from "components/CustomButtons/Button.js";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "../../../components/InfoArea/InfoArea.js";

import DTLeanAgileImage from "../../../assets/img/DLA.svg";
import styles from "./metodiStyle.js";

const useStyles = makeStyles(styles);

export default function MetodiSection() {
  const metodiGridRef = useRef(null);
  const [show, doShow] = useState({
    itemOne: false,
    itemTwo: false,
    itemThree: false,
    itemThree: false,
  });
  useLayoutEffect(() => {
    // const metodiGridPosition = metodiGridRef.current.getBoundingClientRect().top;
    const onScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      //   if (metodiGridPosition < scrollPosition) {
      //     doShow((state) => ({ ...state, itemOne: true }));
      //   } else {
      //     doShow((state) => ({ ...state, itemOne: false }));
      //   }
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
    /* 
       remove the event listener in the cleanup function 
       to prevent memory leaks
    */
  }, []);

  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center" spacing={8}>
        <GridItem xs={12} sm={12} md={6}>
          <img
            title="Design Thinking, Lean, Agile, Digitalizzazione"
            alt="Design Thinking, Lean, Agile, Digitalizzazione"
            src={DTLeanAgileImage}
            className={classes.imageMax}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={5}>
          <h3>Design Thinking. Lean. Agile. Digitalizzazione</h3>
          <h2 className={classes.title}>Il metodo è nulla senza l'ascolto.</h2>
          <h3 className={classes.description}>
            La realtà di ogni azienda merita un ascolto dedicato. A differenza
            di interventi radicali noi partiamo dal valore esistente in azienda
            per sviluppare solo quanto è necessario, passo dopo passo, per
            ottenere risultati incrementali misurabili e a basso impatto
            economico.
          </h3>
          <Button
            size="lg"
            fullWidth
            variant="text"
            component={Link}
            to="/metodi"
            style={{
              backgroundColor: "#f8d300",
              color: "#222222",
              fontSize: "1rem",
              marginTop: "2rem",
              minHeight: "4rem",
            }}
          >
            <Box component="div" whiteSpace="normal">
              scopri i metodi e come insieme sono più efficaci...
            </Box>
          </Button>
        </GridItem>
      </GridContainer>
      {/* <div> */}
      {/* <GridContainer ref={metodiGridRef} id="metodiGridRef">
        <GridItem xs={12} sm={12} md={3}>
          <InfoArea
            title="DESIGN THINKING"
            description="Identificazione delle esigenze e sviluppo creativo delle soluzioni."
            icon={WbIncandescentIcon}
            iconColor="danger"
            vertical
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <InfoArea
            title="LEAN"
            description="Miglioramento continuo ed eliminazione degli sprechi."
            icon={BlurLinearIcon}
            iconColor="info"
            vertical
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <InfoArea
            title="AGILE"
            description="Sviluppo per passi, coinvolgimento, trasparenza e flessibilità."
            icon={FlipCameraAndroidIcon}
            iconColor="success"
            vertical
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <InfoArea
            title="DIGITAL SOLUTION"
            description="La digitalizzazione snella e su misura, solo quando serve."
            icon={ScreenShareIcon}
            iconColor="warning"
            vertical
          />
        </GridItem>
      </GridContainer> */}
      {/* </div> */}
    </div>
  );
}
