import React, { useEffect, useRef } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

import Grid from "@material-ui/core/Grid";

import styles from "./metodiStyle.js";

const useStyles = makeStyles(styles);

export default function MetodiBreadcrumbs({ dark = false }) {
  return (
    <Grid
      spacing={1}
      container
      justify="flex-start"
      alignContent="center"
      alignItems="center"
      style={{ padding: "0.2rem 1rem 1rem 1rem ", marginBottom: "1rem" }}
    >
      <Grid item>
        <Breadcrumbs
          aria-label="breadcrumb"
          style={dark ? { color: "#ddd" } : { color: "#666" }}
        >
          <Link href="/" color="inherit">
            Home
          </Link>
          <Link href="/metodi" color="inherit">
            I nostri metodi
          </Link>
        </Breadcrumbs>
      </Grid>
    </Grid>
  );
}
