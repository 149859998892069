import React, { useRef, useState } from "react";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";

import classNames from "classnames";

// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Typography from "@material-ui/core/Typography";
import MuiAccordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Link from "@material-ui/core/Link";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styles from "../serviziStyle";

const useStyles = makeStyles(styles);
const Accordion = withStyles({
  expanded: {},
})(MuiAccordion);

import heroVantaggioCompetitivoImages from "../../../assets/img/vantaggio.jpg";

export default function VantaggioEsempi() {
  const classes = useStyles();
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

  const [expanded, setExpanded] = useState("");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className={classes.sectionAlternate}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={5}>
          <h2 className={classes.titleAlternate}>
            Accrescere il vantaggio competitivo
          </h2>
          <h3 className={classes.subTitleAlternate}>Esempi di intervento</h3>
          <Accordion
            className={classes.accordionMargin}
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.accorditionHeading}
            >
              <Typography
                align="left"
                variant="h6"
                className={classes.accordionTitle}
              >
                Il percorso a maggior valore
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                align="left"
                variant="body1"
                className={classes.title}
              >
                Senti che per non perdere il tuo vantaggio competitivo hai
                bisogno di rafforzare il valore della tua offerta in base alle
                esigenze dei tuoi clienti, ma temi che scelte errate possano
                vanificare i tuoi investimenti?
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography align="justify" display="block">
                Noi possiamo affiancarti in un percorso di innovazione che, in
                base all’esperienza dei tuoi utenti, identifichi e valuti le vie
                da percorrere verso il rafforzamento della tua offerta.
                <br />
                L’adozione di pratiche di Design Thinking amplificherà la tua
                forza creativa e ti permetterà di sviluppare nuove idee senza
                mai perdere il contatto con i tuoi stakeholder.
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography align="justify">In che modo?</Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography align="justify">
                Creeremo insieme un team di sviluppo composto da tue persone e
                da tuoi utenti che percorrerà le vie della creatività per
                identificare il percorso a maggior valore per i tuoi clienti.
                <br />
                Faciliteremo lo sviluppo di queste innovazioni seguendo i
                principi Agile, così da mantenere un contatto costante con i
                tuoi stakeholder.
                <br />
                <br />
                Il progetto sarà articolato in due fasi:
                <br />
                &nbsp;&nbsp;- un gruppo di lavoro misto (tuoi interni più utenti
                esterni), che attraverso un percorso creativo identificherà le
                idee che portano più valore ai tuoi clienti;
                <br />
                &nbsp;&nbsp;- un gruppo di sviluppo che attuerà le idee
                identificate procedendo per cicli di verifica continua.
                <br />
                Rimanendo sempre in contatto con i tuoi clienti, e tenendo sotto
                costante controllo sia l’avanzamento delle attività di sviluppo,
                che l’effettiva rispondenza degli obiettivi alle esigenze del
                cliente, riusciremo a produrre innovazione riducendo al minimo i
                rischi di insuccesso.
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography variant="subtitle2" display="block">
                <Link href="/metodi/designthinking" color="secondary">
                  Design Thinking
                </Link>
                {" + "}
                <Link href="/metodi/agile" color="secondary">
                  Agile
                </Link>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className={classes.accordionMargin}
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
              className={classes.accorditionHeading}
            >
              <Typography
                align="left"
                variant="h6"
                className={classes.accordionTitle}
              >
                Più vicino ai clienti
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                align="left"
                variant="body1"
                className={classes.title}
              >
                Sei un fornitore B2B di prodotti e/o semilavorati e credi che i
                tuoi clienti non siano pienamente soddisfatti del tuo servizio,
                ma a volte hai l’impressione di non capire perché si lamentano?
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography align="justify" display="block">
                Noi possiamo aiutarti a rafforzare la comunicazione con i tuoi
                clienti per capire cosa puoi migliorare e, sulla base di queste
                conoscenze, ad aumentare il valore generato dal tuo business.
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography align="justify">In che modo?</Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography align="justify">
                - In primo luogo, rafforzando la comunicazione e lo scambio di
                idee tra la tua azienda e i suoi clienti in modo da identificare
                con chiarezza le loro esigenze espresse ed inespresse.
                <br />
                - Utilizzando, poi, queste informazioni per attuare i
                miglioramenti e le trasformazioni più opportune sul piano
                logistico-produttivo, al fine di generare valore per il cliente
                attraverso una risposta più rapida agli ordini e una produzione
                più fluida e congruente con le reali richieste del cliente, sia
                in termini di puntualità, che di qualità.
                <br />
                <br />
                Se necessario, siamo in grado di supportarti in questo sforzo
                anche sul piano dell’innovazione digitale, accompagnandoti
                nell’adozione degli strumenti più adeguati per sostenere
                l’efficacia ed l’efficienza della tua catena
                logistico-produttiva.
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography variant="subtitle2" display="block">
                <Link href="/metodi/designthinking" color="secondary">
                  Design Thinking
                </Link>
                {" + "}
                <Link href="/metodi/lean" color="secondary">
                  Lean
                </Link>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className={classes.accordionMargin}
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
              className={classes.accorditionHeading}
            >
              <Typography
                align="left"
                variant="h6"
                className={classes.accordionTitle}
              >
                La concorrenza nell'era digitale
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                align="left"
                variant="body1"
                className={classes.title}
              >
                Sei una società di servizi che rischia di perdere la propria
                posizione sul mercato perché fatica a stare al passo con le
                proposte della concorrenza che, oggi, prolificano sul web?
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography align="justify">
                Noi possiamo aiutarti a capire i bisogni e le aspettative dei
                tuoi clienti, attuali e potenziali, in termini di nuovi servizi
                digitali. Daremo forma a questa richiesta di innovazione
                accompagnandoti in un percorso di sviluppo di nuove idee e di
                nuovi prodotti digitali capaci di soddisfare appieno le esigenze
                espresse ed inespresse dei tuoi clienti.
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography align="justify">In che modo?</Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography align="justify">
                - Attraverso un'azione di Design Thinking volta
                all’identificazione del bisogno e alla formulazione di una
                risposta efficace, originale ed innovativa.
                <br />- Attraverso un progetto Agile-Scrum finalizzato alla
                realizzazione di tale innovazione in un ambiente di sviluppo
                collaborativo e fortemente focalizzato sulla produzione di
                valore per i tuoi clienti.
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography variant="subtitle2" display="block">
                <Link href="/metodi/designthinking" color="secondary">
                  Design Thinking
                </Link>
                {" + "}Digitalizzazione
              </Typography>
            </AccordionDetails>
          </Accordion>
        </GridItem>
        <GridItem xs={12} sm={12} md={1}></GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <img
            alt="Consulenza per il vantaggio competitivo"
            title="Consulenza per il vantaggio competitivo"
            src={heroVantaggioCompetitivoImages}
            className={navImageClasses}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
