import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import Header from "../../components/Header/Header";
import HeaderLinks from "../../components/Header/HeaderLinks";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import Grid from "@material-ui/core/Grid";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

import Button from "components/CustomButtons/Button.js";

import styles from "./metodiStyle.js";
import MetodiImage from "../../assets/img/hero-metodi3Medium.jpg";

import DesignThinkingImage from "../../assets/img/hero-dtSmall.jpg";
import AgileImage from "../../assets/img/hero-agileSmall.jpg";
import LeanThinkingImage from "../../assets/img/hero-leanSmall.jpg";

const useStyles = makeStyles(styles);

export default function MetodiPage(props) {
  const topRef = useRef(null);
  const executeScrollToTop = () => {
    topRef.current.scrollIntoView();
  };
  useEffect(executeScrollToTop);

  const classes = useStyles();

  return (
    <div ref={topRef}>
      <Header
        color="transparent"
        brand="Webylia"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "white",
        }}
      />
      <Parallax xsmall filter image={MetodiImage}>
        <div className={classes.parallaxContainer}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <h1 className={classes.parallaxTitle}>Metodi</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8}>
              <h2 style={{ marginBottom: 40 }}>
                <Box fontWeight="Bold">Design Thinking, Agile e Lean.</Box>
              </h2>
              <Typography
                variant="body1"
                // style={{ marginTop: "30px" }}
                component="div"
                align="justify"
                variant="body1"
                gutterBottom
                className={classes.descrizioneVoce}
                style={{ marginBottom: 40 }}
              >
                Nascono da settori differenti con punti di vista diversi: il
                Design Thinking deriva dal problem solving tedesco, dal design
                scandinavo e dagli innovatori californiani; l'Agile viene
                formulato nello Utah nell'ambito dell'informatica; la Lean nasce
                in Giappone nell'industria automobilistica.
              </Typography>
              <GridContainer
                justify="center"
                className={classes.gridTop}
                spacing={3}
              >
                <GridItem className={classes.justifyCenter} xs={12} sm={4}>
                  <Card className={classes.card}>
                    <CardActionArea
                      component={Link}
                      to="/metodi/designthinking"
                    >
                      <CardMedia
                        component="img"
                        alt="Design Thinking"
                        height="140"
                        image={DesignThinkingImage}
                        title="Design Thinking"
                      />
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="h2"
                          className={classes.cardTitle}
                        >
                          Design Thinking
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </GridItem>
                <GridItem className={classes.justifyCenter} xs={12} sm={4}>
                  <Card className={classes.card}>
                    <CardActionArea component={Link} to="/metodi/agile">
                      <CardMedia
                        component="img"
                        alt="Agile"
                        height="140"
                        image={AgileImage}
                        title="Agile"
                      />
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="h2"
                          className={classes.cardTitle}
                        >
                          Agile
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </GridItem>
                <GridItem className={classes.justifyCenter} xs={12} sm={4}>
                  <Card className={classes.card}>
                    <CardActionArea component={Link} to="/metodi/lean">
                      <CardMedia
                        component="img"
                        alt="Lean Thinking"
                        height="140"
                        image={LeanThinkingImage}
                        title="Lean Thinking"
                      />
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="h2"
                          className={classes.cardTitle}
                        >
                          Lean
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </GridItem>
              </GridContainer>
              <Typography
                variant="body1"
                // style={{ marginTop: "30px" }}
                component="div"
                align="justify"
                variant="body1"
                gutterBottom
                className={classes.descrizioneVoce}
                style={{ marginTop: 40, marginBottom: 40, textAlign: "center" }}
              >
                Questi metodi hanno in comune l'obiettivo di affrontare business
                e mercati sempre più volatili, incerti, complessi e ambigui.
                <br />
                Poichè condividono molti aspetti teorici e strumenti pratici, la
                loro applicazione combinata ne moltiplica l'efficacia.
              </Typography>
              <Grid
                spacing={1}
                container
                justify="center"
                alignContent="center"
                alignItems="center"
              >
                <Grid item>
                  <Button
                    size="lg"
                    fullWidth
                    variant="text"
                    component={Link}
                    to="/metodi/in_comune"
                    style={{
                      backgroundColor: "#f8d300",
                      color: "#222222",
                      fontSize: "1rem",
                      marginTop: "2rem",
                      minHeight: "4rem",
                      minWidth: "24vw",
                    }}
                  >
                    <Box component="div" whiteSpace="normal">
                      Cosa hanno in comune?
                    </Box>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    size="lg"
                    fullWidth
                    variant="text"
                    component={Link}
                    to="/metodi/insieme"
                    style={{
                      backgroundColor: "#f8d300",
                      color: "#222222",
                      fontSize: "1rem",
                      marginTop: "2rem",
                      minHeight: "4rem",
                      minWidth: "24vw",
                    }}
                  >
                    <Box component="div" whiteSpace="normal">
                      Come funzionano insieme?
                    </Box>
                  </Button>
                </Grid>
              </Grid>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
